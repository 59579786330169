import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import * as Icons from "@material-ui/icons";

import {
  Button,
  Grid,
  createStyles,
  makeStyles,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Fab,
} from "@material-ui/core";

import CustomDialog from "../../../../Components/CustomDialog";
import { MaskCel, MaskPhone } from "../../../../Components/Helper/FormatValues";
import { SelectApi } from "../../../../Components/SelectsApi";
import {
  GET_LEVELUSER,
  GET_SECRETARY,
  POST_UPDATE_USER,
} from "../../../../services/options";
import NewPassword from "./NewPassword";
import ConfirmDelete from "./ConfirmDelete";
import useAxios from "../../../../Context/Hooks/useAxios";
import { useSnackbar } from "notistack";
import api from "../../../../services/Api";
import { Box } from "@material-ui/core";
import { UserContext } from "../../../../Context/UserContext";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const validationSchema = Yup.object().shape({
  form_name: Yup.string()
    .required("Obrigatório")
    .min(4, "O nome deve ter pelo menos 4 caracteres"),
  responsibleSecretary: Yup.object().shape().required("Obrigatório").nullable(),
  accessLevel: Yup.object().shape().required("Obrigatório").nullable(),
  form_email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  status: Yup.number().required("Obrigatório"),
});

export const GET_USERFORM_INFO = (id) => {
  return {
    method: "get",
    url: `user/description.php?id=${id}`,
  };
};

const EditUser = ({ userId, open, setOpen,  updateData }) => {
  const classes = useStyles();
  const [info, setInfo] = React.useState(null);
  const [pass, setPass] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { request } = useAxios();
  const { enqueueSnackbar } = useSnackbar();

  const getData = async () => {
    await api(GET_USERFORM_INFO(userId))
      .then((r) => {
        const buffer = r.data[0];
        setInfo({
          form_name: buffer.name,
          form_email: buffer.email,
          form_phone: buffer.phone,
          form_cellPhone: buffer.cellPhone,
          responsibleSecretary: buffer.responsibleSecretary,
          accessLevel: buffer.accessLevel,
          status: Number(buffer.status),
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Erro!`, { variant: "Error" });
      });
  };

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      id: userId,
      name: values.form_name,
      email: values.form_email,
      phone: values.form_phone,
      cellPhone: values.form_cellPhone,
      responsibleSecretaryId: values.responsibleSecretary.id,
      accessLevelId: values.accessLevel.id,
      status: values.status,
    };
    await request(POST_UPDATE_USER(buffer))
      .then((r) => {
        updateData();
        setSubmitting(false);
        enqueueSnackbar("Usuário atualizado !!!", { variant: "success" });
        Close();
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
      });
  };

  const ChangePassword = () => {
    setPass(true);
  };

  const Close = async () => {
    if (openDelete) await updateData();
    setOpen(false);
    setInfo(null);
  };

  React.useEffect(() => {
    if (userId !== null ) getData();
  }, [userId]);

  return (
    <>
      <NewPassword userId={userId} open={pass} setOpen={setPass} />
      <ConfirmDelete userId={userId} open={openDelete} setOpen={setOpenDelete} handleClose={Close}/>
      <DefaultModal 
        loading={info === null}
        open={open}
        handleClose={Close}
        title={`Editar usuário`}
        actionsTitle={
        <Box>
          <Tooltip placement="top"title="Apagar usuário"aria-label="Apagar usuário">
            <Fab
              size="small"
              style={{ backgroundColor: "red", color: "white", marginRight: "1rem"}}
              onClick={() => setOpenDelete(true)}
            >
              <Icons.Delete />
            </Fab>
          </Tooltip>

          <Tooltip placement="top" title="Alterar senha" aria-label="Apagar usuário">
            <Fab
              size="small"
              color="primary"
              style={{backgroundColor:"black"}}
              onClick={()=>ChangePassword()}
            >
              <Icons.VpnKey/>
            </Fab>
          </Tooltip>
        </Box>    
        }
      >
       <Formik
         initialValues={info}
         validationSchema={validationSchema}
         onSubmit={submit}
         >
       {(props) => <Forms ChangePassword={ChangePassword} {...props} />}
       </Formik>
      </DefaultModal>
    </>
  );
};

export default EditUser;

const Forms = (props) => {
  const classes = useStyles();
  const { privilege } = React.useContext(UserContext);
  const {
    values: {
      form_name,
      form_email,
      form_phone,
      form_cellPhone,
      status,
      responsibleSecretary,
      accessLevel,
    },
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleReset,
    ChangePassword,
  } = props;

 return (
  <form onSubmit={handleSubmit} noValidate>
   <DefaultModalContent dividers >
    <Grid container className={classes.root} spacing={3}>
     <Grid item xs={12}>
      <TextField
       fullWidth
       multiline
       maxRows={5}
       variant="outlined"
       size="small"
       id="form_name"
       name="form_name"
       label="Nome"
       inputProps={{ autoComplete: "off" }}
       autoComplete="off"
       onChange={handleChange}
       value={form_name}
       error={touched.form_name && Boolean(errors.form_name)}
       helperText={touched.form_name && errors.form_name}/>
     </Grid>
     <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="form_email"
            name="form_email"
            label="Email"
            inputProps={{ autoComplete: "off" }}
            autoComplete="off"
            onChange={handleChange}
            value={form_email}
            error={touched.form_email && Boolean(errors.form_email)}
            helperText={touched.form_email && errors.form_email}
          />
     </Grid>
     <Grid item xs={12} style={{display: "flex"}}>
      <Grid item xs={6}>
          <TextField
            style={{width:"90%"}}
            variant="outlined"
            size="small"
            fullWidth
            id="form_phone"
            name="form_phone"
            label="Telefone"
            InputProps={{ inputComponent: MaskPhone }}
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={form_phone}
            //error={touched.form_phone && Boolean(errors.form_phone)}
            //helperText={touched.form_phone && errors.form_phone}
          />
      </Grid>
      <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="form_cellPhone"
            name="form_cellPhone"
            label="Celular"
            InputProps={{ inputComponent: MaskCel }}
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={form_cellPhone}
            //error={touched.cellPhone && Boolean(errors.cellPhone)}
            //helperText={touched.cellPhone && errors.cellPhone}
          />
      </Grid>
    </Grid> 
    <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(e, value) =>
              setFieldValue("responsibleSecretary", value || null)
            }
            value={responsibleSecretary}
            config={GET_SECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Secretaria",
            }}
            error={
              touched.responsibleSecretary &&
              Boolean(errors.responsibleSecretary)
            }
            helperText={
              touched.responsibleSecretary && errors.responsibleSecretary
            }
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="accessLevel"
            name="accessLevel"
            onChange={(e, value) => setFieldValue("accessLevel", value || null)}
            value={accessLevel}
            getOptionDisabled={(option) =>
              privilege.collaboratorNv1 &&
              (option?.id === "1" || option?.id === "2")
            }
            config={GET_LEVELUSER()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Nível de acesso",
            }}
            error={touched.accessLevel && Boolean(errors.accessLevel)}
            helperText={touched.accessLevel && errors.accessLevel}
          />
        </Grid>

    
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              aria-label="status"
              name="status"
              id="status"
              value={status}
              onChange={(e, value) => setFieldValue("status", Number(value))}
            >
             <Box style={{display: "flex"}}>  
              <FormControlLabel value={1} control={<Radio />} label="Ativo" />
              <FormControlLabel value={0} control={<Radio />} label="Inativo" />
             </Box>
            </RadioGroup>
          </FormControl>
        </Grid>
     
      </Grid>
   </DefaultModalContent>
    <DefaultModalActions style={{marginRight: "0.7rem"}}>
     <ButtonSuccess
      style={{ width: 120 }}
      variant="contained"
      color="primary"
      type="submit"
      success={false}
      loading={isSubmitting}>
      Salvar
    </ButtonSuccess>
   </DefaultModalActions>      
  </form>
  );
};
