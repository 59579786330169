import React from "react";
import { Grid } from "@material-ui/core";

import {
  CardSecretary,
  CardTask,
  CardProgress,
  CardMoney,
} from "./Cards";

import ModalSecretary from "./Modals/ModalSecretary";
import ModalTasks from "./Modals/ModalTasks";
import ModalProgress from "./Modals/ModalProgress";
import ModalFinances from "./Modals/ModalFinances";

import { useParams } from "react-router-dom";
import api from "../../../../services/Api";
import ModalPhy from "./Modals/ModalPhy";

const GET_CARDS_STATISTICS = (id) => {
  return {
    method: "get",
    url: `project/statistic.php?id=${id}`,
  };
};

export default function StatisticsPanel() {
  const { id } = useParams();
  const [data, setData] = React.useState({
    secretaryNumber: undefined,
    taskNumber: undefined,
    progress: undefined,
    executeValue: undefined,
  });

  const updateData = async () => {
    await api(GET_CARDS_STATISTICS(id))
      .then((r) => {
        setData({
          secretaryNumber: r.data.secretaryNumber,
          taskNumber: r.data.taskNumber,
          progress: r.data.progress,
          executeValue: r.data.executeValue,
        });
      })
      .catch((e) => {});
  };

  React.useEffect(() => {
    updateData();
  }, [id]);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}  >

    <Grid container xs={12} sm={12} md={12} lg={12} xl={9} direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{marginLeft:"1px", marginTop: "1px"}} >
      <Grid item xs={6} sm={6} md={3} lg={3}  xl={3}>
        <CardSecretary value={data.secretaryNumber} />
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3}  xl={3}>
        <CardTask value={data.taskNumber} />
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3}  xl={3}>
        <CardProgress value={data.progress} />
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={3}  xl={3}>
        <CardMoney value={data.executeValue} />
      </Grid>
    </Grid>

      <Grid item xs={12} sm={12} md={9} lg={9}  xl={9}>
        <ModalTasks />
      </Grid>

      <Grid item xs={12} sm={12} md={3} lg={3}  xl={3}>
        <ModalProgress />
      </Grid>

      <Grid item xs={12}  sm={12} md={5} lg={5} xl={5}>
        <ModalSecretary />
      </Grid>

      <Grid item xs={12}  sm={12} md={7} lg={7} xl={7}>
        <ModalFinances />
      </Grid>

      <Grid item xs={12}>
        <ModalPhy/>
      </Grid>
    </Grid>
  );
}

/*
etapas por secretaria
          - responsavel
          - Prioridade
          - Recurso $$

Resolvido     - secretaria
              - responsavel
              - Prioridade
              - Recurso $$


Não resolvido - secretaria
              - responsavel
              - Prioridade
              - Recurso $$
              
https://codesandbox.io/s/react-google-charts-column-chart-6o1dy?file=/src/index.js

*/
