import React from 'react'
import CustomTable from "../../../../Components/CustomTable";
import ViewModal from './ViewModal';
import {ViewTextEditor} from "../../../../Components/Editor";
import { Box } from '@material-ui/core';
import { truncarText } from '../../../../Components/Helper/FormatValues';
 
export default function ReadTable({data, error, load }) {
  const [select, setSelect] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClick = (rowState)=>{
    const index = rowState.dataIndex
    setSelect({
      name: data[index].userName,
      secretary: data[index].secretary,
      date: data[index].date,
      action: data[index].action,
      justification: data[index]?.justification ? data[index]?.justification : undefined,
    })
    setOpen(true) 
  }

  const options ={
    search: true,
    print: true,
    viewColumns: true,
    onRowClick: (rowData, rowState) => handleClick(rowState)
  }

  const handleClose=()=>{
   setOpen(false)
   setSelect(null)
  }

  return (
    <>
     <ViewModal data={select} open={open} handleClose={handleClose}/>
     <CustomTable data={data} columns={columns} opt={options} error={error} load={load}/>
    </>
    )
  }

  const columns = [
    { name: "id", options: { display: "false" } },
    { name: "date", label: "Data", options: { filter: true, sort: true } },
    { name: "userName", label: "Usuário", options: { filter: true, sort: true } },
    { name: "secretary", label: "Secretaria", options: { filter: true, sort: true } },
    { name: "action", label: "Atividade", options: { filter: true, sort: true,
     customBodyRender: (value) => 
     <Box>
      <ViewTextEditor value={truncarText(value, 100)} maxLength={100}/> 
     </Box>
  }
  },
  ];
  
  