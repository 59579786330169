import React from "react";
import * as Icons from "@material-ui/icons";
import { Grid, createStyles, makeStyles, Box, Fab, Tooltip, List, ListItem, ListItemIcon, Typography, ListItemText } from "@material-ui/core";
import DefaultModal, {
  DefaultModalContent,
} from "../../../Components/DefaultModal";
import EditUser from "./EditUser";
import api from "../../../services/Api";
import { ViewLog } from "./ViewLog";
import { UserContext } from "../../../Context/UserContext";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    padding: "1rem 0px 1rem 0px",
    margin: 0,
  },
  main:{
     minHeight: 250,
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  }
})
);

//user/accessLog.php?id=ID DO USUARIO
export const GET_USERFORM_INFO = (id) => {
  return {
    method: "get",
    url: `user/description.php?id=${id}`,
  };
};

export const ViewUser = ({ id, open, setOpen, updateData }) => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = React.useState(false);
  const { privilege } = React.useContext(UserContext);
  const [log, setLog] = React.useState(false);
  const [data, setData] = React.useState(null);

  const getData = async () => {
    await api(GET_USERFORM_INFO(id)).then((r) => {
      const buffer = r.data[0];
      setData([
        {
          icon: <Icons.AccountBox/>,
          text: "Nome: ",
          data: buffer.name,
        },
        {
          icon: <Icons.Email/>,
          text: "Email: ",
          data: buffer.email,
        },
        {
          icon: <Icons.Phone />,
          text: "Telefone: ",
          data: buffer.phone,
        },
        {
          icon: <Icons.Smartphone />,
          text: "Celular: ",
          data: buffer.cellPhone,
        },
        {
          icon: <Icons.AccountBalance />,
          text: "Secretaria: ",
          data: buffer.responsibleSecretary.name,
        },
        {
          icon: <Icons.HowToReg/>,
          text: "Nível de acesso: ",
          data: buffer.accessLevel.name,
        },
        {
          icon: <Icons.CheckCircle />,
          text: "Status: ",
          data: buffer.status === "1" ? "Ativo" : "Inativo",
        },
      ]);
    });
  };
 
  React.useEffect(() => {
    if (open) getData();
  }, [open]);

  return (
    <>
      {openEdit && (
        <EditUser
          userId={id}
          open={openEdit}
          setOpen={setOpenEdit}
          updateData={()=>{
          updateData()
          getData()
          }}
        />
      )}

      <ViewLog open={log} setOpen={setLog} userId={id}/>

      <DefaultModal
        loading={data === null}
        open={open}
        handleClose={setOpen}
        title={`Usuário`}
        actionsTitle={
          <Box>
            <Tooltip placement="top" title="Histórico de acesso" aria-label="Historico de acesso">
              <span>
                <Fab size="small" color="primary" aria-label="edit" 
                  onClick={()=>setLog(true)}>
                  <Icons.Book />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip placement="top" title="Editar usuário" aria-label="Editar usuário">
              <span>
                <Fab
                  style={{ marginLeft: "1rem" }}
                  size="small"
                  color="primary"
                  aria-label="edit"
                  disabled={!privilege.administrator}
                  onClick={()=>setOpenEdit(true)}
                >
                  <Icons.Edit />
                </Fab>
              </span>
            </Tooltip>
          </Box>
        }
      >
        <DefaultModalContent dividers className={classes.main}>
         <Grid container className={classes.root}>
          <Grid item xs={12}>
           <List dense disablePadding>
           {data !== null && data.filter(r=> r.data !== null).map((value) => (
            <ListItem  alignItems="start" key={value.text}>
             <ListItemIcon >{value.icon}</ListItemIcon>
             <ListItemText style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
              primary={<Typography variant="h6" component="span">{value.text}</Typography>}
              secondary={
              <Typography
               component="span"
               style={{marginLeft: "0.5rem" }}
               color="textSecondary">
               {value.data} 
              </Typography>}/>
            </ListItem>))}
           </List>
          </Grid>
         </Grid>
        </DefaultModalContent>
      </DefaultModal>
    </>
  );
};
