import {
    Box,
    CircularProgress,
    createStyles,
    makeStyles,
  } from '@material-ui/core';
  
  const useStyles = makeStyles((theme) =>
    createStyles({
      loading: {
      //  margin:"0 auto",
      },
      loadingProgress: {
        margin: theme.spacing(1),
        minWidth: 35,
        minHeight: 35,
      },
    })
  );
  
  export default function FormLoadingComponent({size}) {
    const classes = useStyles();
    return (
      <Box className={classes.loading}>
        <CircularProgress
          className={classes.loadingProgress}
          disableShrink
          color="primary"
          size={size}
        /> 
        {/* <Typography>Aguarde...</Typography> */}
      </Box>
    );
  }
  
  FormLoadingComponent.defaultProps ={
    size: 20
  }