import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import clsx from 'clsx';

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Divider,
  Box,
  IconButton,
  LinearProgress,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import api from "../../services/Api";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      minHeight: "100vh",
      gap: theme.spacing(5),
      padding: theme.spacing(2),
      backgroundColor: "#FFFFFF",
    },
    form: {
      padding: theme.spacing(2),
      width: "400px",
    },
    submit: {
      marginTop: theme.spacing(2),
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
  })
);

const initialValues = {
  form_password: "",
  form_confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  form_password: Yup.string()
    .required("Obrigatório")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial"
    ),
  form_confirmPassword: Yup.string()
    .required("Obrigatório")
    .oneOf(
      [Yup.ref("form_password"), null],
      "As senhas não correspondem"
    ),
});

//user/resetPassword.php

export const RQST = (body) => {
  return {
    url: "user/resetPassword.php",
    method: "post",
    headers: {
      'Content-Type': 'application/json',
     },
    data: body,
  };
};


export default function NewPassword() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
     const buffer = {
       token: id,
       password: values.form_password
     }

      await api(RQST(buffer)).then(r=>{
       const {data} = r;
       if(data.status === "success"){
         enqueueSnackbar("Senha alterada !!!", { variant: "success" }) 
         setSubmitting(false);
         navigate('/auth')
       }else{
         setSubmitting(false);
         enqueueSnackbar(`Erro ao trocar a senha !!!`, { variant: "error" });
       } 
      }).catch(e=>{
       setSubmitting(false);
       enqueueSnackbar(`Erro ao trocar a senha !!!`, { variant: "error" });
      })
 
     },
   });

  return (
    <div className={classes.root}>
      <Box style={{ marginRight: "2rem" }}>
        <img
          src="/logo.png"
          alt="SISTEMA DE GERENCIAMENTO DE PROJETOS MUNICIPAIS"
        />
      </Box>
      <Paper className={classes.form} elevation={3}>
        <Box textAlign="center">
          <Typography component="h3" variant="h5" gutterBottom>
            Cadastre a nova senha
          </Typography>
        </Box>

        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="form_password"
            name="form_password"
            type={showPassword ? "text" : "password"}
            label="Nova senha"
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={values.form_password}
            error={touched.form_password && Boolean(errors.form_password)}
            helperText={touched.form_password && errors.form_password}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="form_confirmPassword"
            id="form_confirmPassword"
            label="Confirme a nova senha"
            type={showConfirmPassword ? "text" : "password"}
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirmPassword visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={values.form_confirmPassword}
            error={
              touched.form_confirmPassword &&
              Boolean(errors.form_confirmPassword)
            }
            helperText={
              touched.form_confirmPassword && errors.form_confirmPassword
            }
          />

          <Button
            style={{marginTop: "0.5rem"}}
            className={clsx({[classes.buttonSuccess]: isSubmitting}, classes.submit)}
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            
            disabled={isSubmitting}
          >
            Confirmar
          </Button>
          {isSubmitting && <LinearProgress/>} 
        </form>
      </Paper>
    </div>
  );
}
