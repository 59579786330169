import React from "react";
import { useParams } from "react-router-dom";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  DialogContentText,
    Typography, 
  } from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { GET_RQSTINFO_PROJECT, POST_UPDATE_PROJECT } from "../../../../services/options";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import { TextEditor } from "../../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";
 
 const validationSchema = Yup.object().shape({
  justification: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
 });

export default function PauseProject({open, setOpen, updateData}) {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    
    const [data, setData] = React.useState(null)
    const rqstInfo = async ()=>{
      await api(GET_RQSTINFO_PROJECT(id)).then((r) => {
        const {data} = r;
        setData({...data, projectId: id, justification: ""})
      }).catch(e=>{
        enqueueSnackbar(`Erro`, { variant: "Error" })
      })
    }

    React.useEffect(()=>{
     if(open) rqstInfo()   
    },[open])
    
    const submit = async(values, { setSubmitting }) => {
     const buffer ={...values, canceled: 0, stoped: 1}
     await api(POST_UPDATE_PROJECT(buffer)).then(r=>{
       const {data} = r;
       if(data.status === "success"){
        enqueueSnackbar("Projeto suspenso !!!", { variant: "success" });
        updateData()
        setOpen(false);
       }else{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao suspender o projeto`, { variant: "Error" })
       }
      }).catch(r=>{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao suspender o projeto`, { variant: "Error" })
      })
    }
    
    const closeModal =()=>{
      setOpen(false)
    }
    
    return (
      <DefaultModal
        open={open}
        loading={data === null}
        handleClose={closeModal}
        title="Suspender projeto"
      >
        <Formik
          setOpen={closeModal}
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {(props) => <Forms {...props} />}
        </Formik>
      </DefaultModal>
    );
  }


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: { maxWidth: 380 },
      [theme.breakpoints.up("sm")]: { maxWidth: 450 },
      [theme.breakpoints.up("md")]: { maxWidth: 500 },
      [theme.breakpoints.up("lg")]: { maxWidth: 500 },
      [theme.breakpoints.up("xl")]: { maxWidth: 550 },
    },
  })
);

const Forms = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers className={classes.root}>
        <DialogContentText>
        <Typography variant="subtitle1" gutterBottom>
          Justifique a suspensão do projeto!
         </Typography>
        </DialogContentText>

        <TextEditor
          id="justification"
          name="justification"
          label="Justificativa"
          onChange={(event) => setFieldValue("justification", convertToHTML(event.getCurrentContent()))}
          value={values.justification}
          error={touched.justification && Boolean(errors.justification)}
          helperText={touched.justification && errors.justification}
        />
      </DefaultModalContent>
      <DefaultModalActions>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
        >
          Confirmar
        </ButtonSuccess>
      </DefaultModalActions>
    </form>
  );
};
