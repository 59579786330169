import React from "react";
import {
  Typography,
  Button,
  DialogContentText,
} from "@material-ui/core";

import api from "../../../../services/Api";
import { GET_DELETE_GUT } from "../../../../services/options";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";

export default function ConfirmDelete({ gutId, updateData, open, setOpen, handleClose}) {
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = React.useState(false)

  const confirm = async ()=>{
  setLoad(true)
  await api(GET_DELETE_GUT(gutId)).then(r=>{
      const {data} = r;
      if(data.status === "success"){
        updateData()
        setLoad(false)
        handleClose(false)
        enqueueSnackbar("GUT apagado !!!", { variant: "success" });
      }else{
        setLoad(false)
        enqueueSnackbar(`Error ao apagar GUT!!!`, { variant: "error" });
      }
     }
    ).catch(error=>{
      setLoad(false)
      enqueueSnackbar(`Error ao apagar GUT ${error?.message} !!!`, { variant: "error" });
    })
   }

  return (
    <DefaultModal open={open} handleClose={() => setOpen(false)} title={"Apagar GUT"}>
      <DefaultModalContent dividers>
      <DialogContentText  style={{padding:"0.5rem"}}>
        <Typography component="h1">
          Deseja apagar permanentemente o GUT?
        </Typography>
        <Typography component="h1">
          apagando o GUT automaticamente será apagado o 5W2H!
        </Typography>
      </DialogContentText>
      </DefaultModalContent>

      <DefaultModalActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        > Não
        </Button>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={load}
          disabled={load} 
          onClick={confirm}
        > Sim
        </ButtonSuccess>
      </DefaultModalActions>
    </DefaultModal>
  );
}
