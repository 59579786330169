import React from "react";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, Grid, Typography, Divider } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
   display: "grid",
   boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
   border: `2px solid  ${theme.palette.divider}`,
   borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: 5,
    margin: 0,
  },
  graphic:{
    margin: 0,  
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 250},
    [theme.breakpoints.up("md")]: {width: "100%", height: 250},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 320},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 350},
  }
})
);

function ViewChart({series,}){
  const options = {
    chart: {
      //height: 350,
      type: 'radar',
    },
    xaxis: {
      categories: ['Nível 1', 'Nível 2', 'Nível 3', 'Nível 4', 'Nível 5']
    }
  };
  return <ReactApexChart options={options} series={series} type="radar" width="100%" height="100%"/>
}

export const Level = ({value}) => {
  const classes = useStyles();
  const { id } = useParams();

  const [data, setData] = React.useState([{
    name: 'Riscos',
    data: value,
  }]);

 
 return (
 <Grid container className={classes.root} >
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> Níveis dos problemas </Typography>
   <Divider/>
   </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart series={data}/>
  </Grid>
 </Grid>
 );
}

