import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";

import { LayoutContext } from "./../../Components/Layout/LayoutMaster";
import { menuAdmin } from "./../../Components/Layout/LayoutMaster/menus";
import Axis from "./Axis";
import Group from "./Group";
import Notification from "./Notification";
import Secretary from "./Secretary";
import User from "./User";

const Administrator = () => {
  const navigate = useNavigate()
  const {setShowLayout, setListMenu} = React.useContext(LayoutContext);
  const {privilege} = React.useContext(UserContext);

  React.useEffect(()=>{
    if(!privilege.administrator) return navigate('/projects') //só adm tem acesso a area de adm
    setShowLayout(true)
    setListMenu(menuAdmin)
  },[])

 return (
  <Routes>
   <Route path="/user"         element={<User />}/>
   <Route path="/secretary"    element={<Secretary/>}/>
   <Route path="/group"        element={<Group/>}/>
   <Route path="/axis"         element={<Axis/>}/>
   <Route path="/notification" element={<Notification/>}/>
  </Routes>
  );
};

export default Administrator;
