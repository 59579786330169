import React from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Grid,
  Container,
  createStyles,
  makeStyles,
  Paper,
  Typography,
  Fab,
  Tooltip
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";
import { useSnackbar } from "notistack";
import DialogTask from "./DialogTask";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import {  GET_TASK_LIST } from "./../../../services/options";
import FilterTask from "../../../Components/Screens/FilterTask";
import { UserContext } from "../../../Context/UserContext";
import useAxios from "../../../Context/Hooks/useAxios";
import { ViewProjectContext } from "..";
import Kanban from "./Kanban";
import Table from "./Table";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    main: {
      display: "grid",
    },
    paper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1),
      borderRadius: "5px",
    },
    table:{
      paddingTop: 5,
      borderRadius: "0.6rem",
      [theme.breakpoints.up("xs")]: { minHeight: 450, },
      [theme.breakpoints.up("sm")]: { minHeight: 450, },
      [theme.breakpoints.up("md")]: { minHeight: 450, },
      [theme.breakpoints.up("lg")]: { minHeight: 450, },
      [theme.breakpoints.up("xl")]: { minHeight: 650, },
    },
  })
);

const initial = {
  name: "",
  responsible: null,
  responsibleSecretary: null,
  status: null,
  priority: null,
  situation: null,
};

export default function MainTask() {
  const classes = useStyles();
  const { id } = useParams();
  const { infosData } = React.useContext(ViewProjectContext);
  const { privilege } = React.useContext(UserContext);
  const { name, isCanceled } = infosData;
  
  const [ search, setSearch ] = React.useState(initial);
  const [ drawerOpen, setDrawerOpen ] = React.useState(false);
  const [ open, setOpen ] = React.useState(false);
  const { buffer, loading, error, request } = useAxios();
  const [ viewType, setViewType ] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const updateData = async () => {
    await request(GET_TASK_LIST({ ...search, id }))
    .catch(e=>{
      enqueueSnackbar("Erro ao atualizar lista", { variant: "Error" });
    });
  };

  React.useEffect(() => {
      updateData();
  }, [search, request]);

  function getActiveView(value) {
    switch (value) {
     case 0: return <Table  data={buffer} updateData={updateData} error={error} load={loading}/>
     case 1: return <Kanban list={buffer} updateData={updateData} error={error} load={loading} />
      default: return null;
    }
  }

 return (
 <div className={classes.root}> 
  <DialogTask open={open} setOpen={setOpen} updateData={updateData}/>
  <FilterTask drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} search={search} setSearch={setSearch}/>
  <Container className={classes.main} maxWidth="xl">

    <Grid item xs={12}>
     <CurrentPageInfo primary="Etapas" secondary={name} />
    </Grid>

    <Grid item xs={12}>
     <Paper className={classes.paper}>
      <Grid item xs={4} style={{ maxWidth: 250, marginRight: 20 }}>
       <Button
        variant="contained"
        color="primary"
        onClick={() => setDrawerOpen(!drawerOpen)} >
         <Typography>Filtro</Typography>
          <Icons.Search style={{ marginLeft: 25 }} />
       </Button>
      </Grid>
      <Grid item xs={4}>
       <Tooltip placement="top" title="Lista" aria-label="List" color={viewType=== 0 ?'primary':'default'} >
        <Fab
        size="small"
        aria-label="View List"
        onClick={() => setViewType(0)}>
         <Icons.List/>
        </Fab>
       </Tooltip>
       <Tooltip placement="top" title="Kanban" aria-label="Kanban" color={viewType=== 1 ? 'primary':'default'} style={{marginLeft: 10}}>
        <Fab
         size="small"
         aria-label="View Kanban"
         onClick={() => setViewType(1)}>
          <Icons.TableChart/>
          </Fab>
        </Tooltip>  
      </Grid>
      <Grid item xs={4} style={{ alignitens: "right", maxWidth: "150px" }} >
       <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        disabled={ privilege.cityHall || isCanceled} >
         Nova Etapa
       </Button>
      </Grid>
     </Paper>
    </Grid>
    <Grid item xs={12} className={classes.table}>
     {getActiveView(viewType)}
    </Grid>
    </Container>
 </div>
  );
}
