import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 2px 4px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "0.6rem",
    position: "relative",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0 , 0 ,0),
  },
  boxicon: {
    boxShadow: "6px 4px 6px rgba(50, 50, 93, 0.11), 0 4px 3px rgba(0, 0, 0, 0.08)",
     
    [theme.breakpoints.up("xs")]: {width: theme.spacing(6), height: theme.spacing(6),},   
    [theme.breakpoints.up("sm")]: {width: theme.spacing(7), height: theme.spacing(7),},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(6), height: theme.spacing(6),},  
    [theme.breakpoints.up("lg")]: {width: theme.spacing(7), height: theme.spacing(7),},  
    [theme.breakpoints.up("xl")]: {width: theme.spacing(8), height: theme.spacing(8),},  
    display: "flex",
    position: "absolute",
    zIndex: 1,
    top: -16,
    left: 12,
    borderRadius: "25%",
    justifyContent: "center",
    alignItems: "center"
  },
  icons: {
    color: "#ffffff",
    [theme.breakpoints.up("xs")]: {width: theme.spacing(5), height: theme.spacing(5)},      
    [theme.breakpoints.up("sm")]: {width: theme.spacing(6), height: theme.spacing(6)},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(5), height: theme.spacing(5)},    
    [theme.breakpoints.up("lg")]: {width: theme.spacing(5), height: theme.spacing(5)},    
    [theme.breakpoints.up("xl")]: {width: theme.spacing(6), height: theme.spacing(6)},     
  },
  secondary: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0)
  },
  value: {
    padding: theme.spacing(0, 1, 0 ,1),
    margin: theme.spacing(0)
  },
  primary: {
    padding: theme.spacing(0),
    margin: theme.spacing(1)
  },
}));

export default function CardStyle(props) {
  const classes = useStyles();
  const {customIcon, color, value, primary, secondary} = props;
  const IconPerformance = customIcon;
  return (
   <Paper className={classes.card}>
    <Grid container>
     {/* <Hidden only="md"> */}
      <Grid className={classes.boxicon} style={{ background: color }}>
       <IconPerformance className={classes.icons} />
      </Grid>
     {/* </Hidden> */}
     <Grid container className={classes.value} justifyContent="flex-end">
     {value}
     </Grid>
    </Grid>
    <Grid container>
     <Grid container justifyContent="flex-end" className={classes.primary}>
      {primary}
    </Grid>
    </Grid>
    {secondary &&
    <> 
     <Divider/>
      <Grid container xs justify="flex-start" className={classes.secondary}>
        {secondary}
      </Grid>
     </>}
    </Paper>
  );
}
