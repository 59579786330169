import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FormatDate, FormatRealNumber, truncarText } from '../../../../Components/Helper/FormatValues';
import { ShowPriority, ShowSituation } from '../../../../Components/ShowInfos';
import { Box, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "1rem",
    cursor:'pointer',
    [theme.breakpoints.up("xs")]: { width: 250, },
    [theme.breakpoints.up("sm")]: { width: 250, },
    [theme.breakpoints.up("md")]: { width: 270, },
    [theme.breakpoints.up("lg")]: { width: 270, },
    [theme.breakpoints.up("xl")]: { width: 320, },
  },
  info:{
    margin: 0,
    padding: 0, 
    lineHeight: "80%",
    fontSize: "0.85rem",
  },
  subInfo:{
    display: "inline", 
    //width: 100, 
    margin: 0, 
    padding: 0, 
    lineHeight: "80%" 
  },
}));

export const CardKanban = (props) => {
  const classes = useStyles();
  const {handleCardClick, taskId, name, responsibleSecretary, responsible, priority, situation, predictedValue, executeValue, initDate, executeDate} = props
  return (
    <Card key={taskId} className={classes.root} onClick={()=>handleCardClick(taskId)}>
     <CardHeader style={{padding: "0.2rem 1rem 0.2rem 1rem"}} title={
      <Typography variant="h6" style={{margin: 0, padding: 0, fontSize: "0.95rem",}}>
       {truncarText(name, 60)}
      </Typography>}/>
     <Divider/>
      <CardContent style={{padding: "0.5rem 1rem 0.5rem 1rem"}}>
      <Box>
       <Typography variant="h6" component="span" className={classes.info}>Secretaria: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" className={classes.subInfo}>
        {responsibleSecretary}
       </Typography>
      </Box>

      <Box>
       <Typography variant="h6" component="span" className={classes.info}>Responsável: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" className={classes.subInfo}>
          {responsible}
         </Typography>
      </Box>

      <Box>
       <Typography variant="h6" component="span" className={classes.info}>Data inicial: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" className={classes.subInfo}>
          {FormatDate(initDate, 'Não definida')}
       </Typography>
      </Box>

      <Box>
       <Typography variant="h6" component="span" className={classes.info}>Data de execução: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" className={classes.subInfo}>
       {FormatDate(executeDate, 'Não definida')}
       </Typography>
      </Box>

      <Box>
       <Typography variant="h6" component="span" className={classes.info}>Valor previsto: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" className={classes.subInfo}>
       {FormatRealNumber(predictedValue)}
       </Typography>
      </Box>

      <Box>
       <Typography variant="h6" component="span" className={classes.info}>Valor executado: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" className={classes.subInfo}>
       {FormatRealNumber(executeValue)}
       </Typography>
      </Box>

      <Box style={{ paddingTop: "0.2rem"}}>
       <Typography variant="h6" component="span" style={{fontSize: "0.90rem", margin: 0, padding: 0}}>Prioridade: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" style={{margin: 0, padding: 0}}>
       <ShowPriority value={priority}/>
       </Typography>
      </Box>

      <Box style={{ paddingTop: "0.2rem"}}>
       <Typography variant="h6" component="span" style={{fontSize: "0.90rem", margin: 0, padding: 0}}>Situação: </Typography>
       <Typography component="span" variant="body2" color="textSecondary" style={{margin: 0, padding: 0}}>
       <ShowSituation value={situation}/>
       </Typography>
      </Box>
      </CardContent>
    </Card>
  )
}
 
