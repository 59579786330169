import React from "react";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";
import { DialogContentText } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { TextEditor } from "./../../../Components/Editor";
import { GET_RQSTFORMINFO_TASK, POST_UPDATE_TASK } from "../../../services/options";
import api from "../../../services/Api";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";
 
 const validationSchema = Yup.object().shape({
  justification: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
 });

export default function ResumeTask({open, setOpen, taskId, updateData}) {
    const [init, setInit] = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const rqstInfo = async ()=>{
      await api(GET_RQSTFORMINFO_TASK(taskId)).then((r) => {
        const data = r.data[0];
        setInit({...data, taskId: taskId, justification:'',})
      }).catch(e=>{
        enqueueSnackbar(`Erro`, { variant: "Error" })
      })
    }

    React.useEffect(()=>{
      if(open) rqstInfo()
    },[open])
    
    const submit = async(values, { setSubmitting }) => {
      const buffer = {
        taskId: values.taskId,
        name: values.name,
        initDate: values.initDate,
        deadLine: values.deadLine,
        predictedValue: !values.predictedValue ? 0 : values.predictedValue,
        description: values.description,
        responsibleSecretaryId: values.responsibleSecretary.id,
        responsibleId: values.responsible.id,
        priorityId: values.priority.id,
        executedDate: values.executedDate === undefined ? "0" : values.executedDate,
        executedValue: !values.executedValue ? 0 : values.executedValue,
        situation: values.situation,
        statusId: values.status.id,
        canceled: 0,
        stoped: 0,
        justification: values.justification,
      }  
     await api(POST_UPDATE_TASK(buffer)).then(r=>{
       const {data} = r;
       if(data.status === "success"){
        updateData()
        setOpen(false);
        enqueueSnackbar("Etapa continuada !!!", { variant: "success" });
       }else{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao continuar a etapa`, { variant: "Error" })
       }
      }).catch(r=>{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao continuar a etapa`, { variant: "Error" })
      })
    }
    
    const closeModal =()=>{
      setOpen(false)
    }
    
    return (
      <DefaultModal
        open={open}
        loading={init === null}
        handleClose={closeModal}
        title="Continuar etapa"
      >
        <Formik
        setOpen={setOpen}
        initialValues={init}
        validationSchema={validationSchema}
        onSubmit={submit}
       >
        {(props) => <Forms {...props}/>}
      </Formik>
    </DefaultModal>
  );
}

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
})
);

const Forms = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers className={classes.root}>
        <DialogContentText>
        <Typography variant="subtitle1" gutterBottom>
          Justifique a continuação da etapa!
         </Typography>
        </DialogContentText>
        <TextEditor
          id="justification"
          name="justification"
          label="Justificativa"
          onChange={(event) => setFieldValue("justification", convertToHTML(event.getCurrentContent()))}
          value={values.justification}
          error={touched.justification && Boolean(errors.justification)}
          helperText={touched.justification && errors.justification}
        />
      </DefaultModalContent>
      <DefaultModalActions>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
        >
          Confirmar
        </ButtonSuccess>
      </DefaultModalActions>
    </form>
  );
};
