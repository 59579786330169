import React from 'react'
import { Box, Typography, createStyles, makeStyles } from '@material-ui/core'
import { truncarText } from './FormatValues';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
     margin: theme.spacing(0, 0, 1, 0),
     display: "grid",
     textAlign:'left',
     alignItems: "baseline"
 
    },
    primary:{
       fontWeight: "500",
      //fontFamily: "sans-serif",
  
      //letterSpacing: "-0.01562em",
      //textShadow: '1px 1px 2px #CCC',
      opacity: 1,
      letterSpacing: '0px',
      [theme.breakpoints.up("xs")]: { fontSize: "1.20rem"},   
      [theme.breakpoints.up("sm")]: { fontSize: "1.20rem"},     
      [theme.breakpoints.up("md")]: { fontSize: "1.25rem"},  
      [theme.breakpoints.up("lg")]: { fontSize: "1.3rem"},  
      [theme.breakpoints.up("xl")]: { fontSize: "1.4rem"},  
    },
    secondary:{
      fontWeight: "500",
      //fontFamily: "sans-serif",
     // textShadow: '1px 1px 2px #CCC',
     // opacity: 1,
      letterSpacing: '0px',
      [theme.breakpoints.up("xs")]: { fontSize: "1.4rem"},   
      [theme.breakpoints.up("sm")]: { fontSize: "1.6rem"},     
      [theme.breakpoints.up("md")]: { fontSize: "1.6rem"},  
      [theme.breakpoints.up("lg")]: { fontSize: "1.7rem"},  
      [theme.breakpoints.up("xl")]: { fontSize: "1.75rem"},  
    }
  })
);

const CurrentPageInfo = ({primary, secondary}) => {
  const classes = useStyles()
   return (
      <Box className={classes.root}>
       <Typography component="span" variant="h6" color="primary" className={classes.primary}>
       {primary}  
       </Typography> 

       <Typography component="span" color="textPrimary" className={classes.secondary}>
       {secondary}  
       </Typography> 
      </Box>      
     )
 }
 
 CurrentPageInfo.defaultProps ={
   primary: "",
   secondary: undefined,
 }
 
export default CurrentPageInfo
