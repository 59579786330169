import React from "react";
import { DialogActions, Typography, Button, Box, DialogContentText} from "@material-ui/core";
import CustomDialog from "../../../../Components/CustomDialog";
import { useSnackbar } from "notistack";
import api from "../../../../services/Api";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions } from "../../../../Components/DefaultModal";
 
const POST_DELETE = (body) =>{
  return {
    url:  "user/delete.php",
    method: 'post',
    data: body,
  };
}

export default function ConfirmDelete({userId, open, setOpen, handleClose}) {
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = React.useState(false)

  const confirm = async ()=>{
     setLoad(true)
    await api(POST_DELETE({id: userId})).then(r=>{
      const {data} = r;

      switch (data.status) {
        case 'success':{
          handleClose(false)
          enqueueSnackbar("Usuário apagado !!!", { variant: "success" }) 
          break;
        }
        case 'caution':{
          setLoad(false)
          handleClose(false)
          enqueueSnackbar(data.msg, { variant: "warning" }); 
          break;
        } 
        case 'error':{
          setLoad(false)
          handleClose(false)
          enqueueSnackbar(data.msg, { variant: "Error" });
          break;
        } 
        default: enqueueSnackbar(data.status, { variant: "Error" });
      }
   }
   ).catch(error=>{
     setLoad(false)
     handleClose(false)
     enqueueSnackbar(`Error ao apagar usuário!!!`, { variant: "error" });
   })
  }

  return (
    <DefaultModal open={open} onClose={()=>setOpen(false)} title="Apagar usuário">

      <DialogContentText style={{padding:"1rem"}}>
       <Typography component="h1">Deseja apagar permanentemente o usuário? </Typography>
      </DialogContentText>

      <DefaultModalActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        > Não
        </Button>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          autoFocus
          success={false}
          loading={load}
          disabled={load} 
          onClick={confirm}
        > Sim
        </ButtonSuccess>
      </DefaultModalActions>

    </DefaultModal>
  );
}
