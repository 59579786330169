import React from "react";

import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Checkbox,
  FormGroup,
  Drawer,
  Box,
  makeStyles, 
  createStyles
} from "@material-ui/core";

import { SelectApi } from "../SelectsApi";
import { Formik } from "formik";


const initial ={
  name: "",
  group: null,
  axis: null,
  responsible: null,
  responsibleSecretary: null,
  status: null,
  type: {
    routine: false,
    governmentPlan: false,
  },
}

export const GET_OLDSECRETARY = () => {
  return { 
   method: 'get',
   url: `projects_old/secretaryList.php`,
  }
 }
 
export default function FilterOldProject({drawerOpen, setDrawerOpen, search, setSearch }) {
  const [init, setInit] = React.useState(initial)
 
  const submit = async (values, { setSubmitting }) => {

    const buffer ={
      name: values.name, 
      responsibleSecretary: values.responsibleSecretary === ""?0:values?.responsibleSecretary?.name,
      }
      setInit(values)
      setSearch(buffer)
      setSubmitting(false);
      setDrawerOpen(false);
  }

  return (
    <Drawer
      open={drawerOpen}
      anchor="right"
      onClose={() => setDrawerOpen(!drawerOpen)}
    >
      <Formik
        initialValues={init}
        //validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms setInit={setInit} {...props} />}
      </Formik>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
     // width: 450,
      margin: "20px auto",
    //  padding: theme.spacing(2, 4, 2, 4),
      [theme.breakpoints.up("xs")]: {width: 250, padding: theme.spacing(0.5, 1, 0, 1),},  
      [theme.breakpoints.up("sm")]: {width: 360, padding: theme.spacing(0.5, 1.5, 0, 1.5),},   
      [theme.breakpoints.up("md")]: {width: 400, padding: theme.spacing(0.5, 2, 0, 2),},  
      [theme.breakpoints.up("lg")]: {width: 450, padding: theme.spacing(0.5, 2.5, 0, 2.5),},  
      [theme.breakpoints.up("xl")]: {width: 500, padding: theme.spacing(0.5, 3, 0, 3),},  
    },

    submitButton: {
      marginTop: theme.spacing(3),
      minWidth: "12rem",
    },
  })
);

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    setInit,
    resetForm,
  } = props;

  const resetValues = async () =>{

  await setInit(initial)
  await resetForm({values:initial})
  }


  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container className={classes.main}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h5">Filtrar projetos por: </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) => {
              setFieldValue("responsibleSecretary", value);
            }}
            value={values.responsibleSecretary}
            config={GET_OLDSECRETARY()}
            //  multiple={true}
            //  limitTags={1}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
          />
        </Grid>

        <Grid item xs={12}>
        <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, marginTop: "1rem"}}>
            <Button
              style={{ marginRight: "1rem", width: 100 }}
              variant="contained"
              color="primary"
              onClick={resetValues}
             // disabled={!dirty || isSubmitting}
            >
              Reiniciar
            </Button>

            <Button
              style={{width: 100 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Filtrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
