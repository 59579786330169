import React from "react";
import { Grid } from "@material-ui/core";
import AmountAxis from "./Graphics/AmountAxis";
import AmountSecretary from "./Graphics/AmountSecretary";
import AmountTypeProject from "./Graphics/AmountTypeProject";
import StatusProjects from "./Graphics/StatusProjects";
import api from "../../../../services/Api";
import { CardSecretary, CardProjects } from "./Cards";
import ModalPhy from "./Graphics/ModalPhy";

/// infos ///
// total de projetos
// total de secretarias

/// graficos ///

// const setLabels = labels =>
//     setOpts({ ...opts, xaxis: { ...opts.xaxis, categories: labels } });
//https://codesandbox.io/s/boring-breeze-75pvw?file=/src/Chart.jsx
//https://codesandbox.io/s/cocky-gauss-d17vr

const GET_INFO = () => {
  return {
    method: "get",
    url: `dashboard/projectProperties.php`,
  };
};

 const init ={
  totalProject: undefined,
  totalSecretary: undefined,
  currentGroup: undefined,
}

export default function StatisticsPanel() {
const [data, setData] = React.useState(init);
//{"totalProject":"15","totalSecretary":"11","currentGroup":{"id":"2","name":"Plano de Governo 2021 - 2024"}}

const updateData = async () => {
 api(GET_INFO()).then(r=>{
  setData(r.data)
 }).catch(e=>{
  setData(init)
 })
}

React.useEffect(() => {
  updateData();
}, []);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>

    <Grid xs={12} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{padding: "0.5rem"}}>
     <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
      <CardProjects value={data.totalProject} />
     </Grid>

     <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
      <CardSecretary value={data.totalSecretary} />
     </Grid>
     </Grid>
  
     <Grid item xs={12} sm={12} md={8}>  
      <AmountSecretary/>
     </Grid>

     <Grid item xs={12} sm={12} md={4}>
      <StatusProjects/>
     </Grid>

     <Grid item xs={12} sm={12} md={8}>
     {data?.currentGroup !== undefined ? <AmountAxis group={data.currentGroup}/> :null}
     </Grid>

     <Grid item xs={12} sm={12} md={4}>
      {data?.currentGroup !== undefined ? <AmountTypeProject group={data.currentGroup}/>: null } 
     </Grid>

     <Grid item xs={12}>
       <ModalPhy/>
     </Grid>



    </Grid>
  );
}

/*
etapas por secretaria
          - responsavel
          - Prioridade
          - Recurso $$

Resolvido     - secretaria
              - responsavel
              - Prioridade
              - Recurso $$


Não resolvido - secretaria
              - responsavel
              - Prioridade
              - Recurso $$
              
https://codesandbox.io/s/react-google-charts-column-chart-6o1dy?file=/src/index.js

*/
