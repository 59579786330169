import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Container,
  Tabs,
  Tab,
  Box,
  withStyles,
  createStyles,
} from "@material-ui/core";

import StatisticsPanel from "./StatisticsPanel";
import DescriptionPanel from "./Description";
import ReportsPanel from "./Reports";
import ActivityPanel from "./ActivityPanel"
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import { ViewProjectContext } from "..";
import { ObservationPanel } from "./Observation"; 

const HorizontalColoredTab = withStyles({
  indicator: {
    background: "#0078B2",
    zIndex: 1,
  },
})(Tabs);

const ColoredTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: "none",
      "&:hover": {
        opacity: 1,
      },
      "&$selected": {
        fontWeight: theme.typography.fontWeightMedium,
        color: "black",
        zIndex: 2,
      },
    },
  })
)((props) => <Tab disableRipple {...props} />);


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
     {value === index && children}  
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    border: `1px solid  ${theme.palette.divider}`,
    borderTop: 0,
    borderRadius: "0px 0px 10px 10px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    margin: "0px auto",
    padding: 10,
    minHeight: 650,
  },
  rootHorizontal: {
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  horizontalTabDivider: {
   border: `1px solid ${theme.palette.divider}`,
   borderBottom: `1px solid ${theme.palette.divider}`,
   borderRadius: "10px 10px 0px 0px",
  },
}));

export default function Resume() {
  const {infosData} = React.useContext(ViewProjectContext);
  const {name} = infosData
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => setValue(newValue);

  const getActiveTab = React.useCallback((value) => { 
    switch (value) {
      case 0: return <TabPanel value={value} index={0}> <DescriptionPanel/> </TabPanel>;
      case 1: return <TabPanel value={value} index={1}> <ObservationPanel/> </TabPanel>;
      case 2: return <TabPanel value={value} index={2}> <StatisticsPanel/>  </TabPanel>;
      case 3: return <TabPanel value={value} index={3}> <ActivityPanel/>    </TabPanel>;
      case 4: return <TabPanel value={value} index={4}> <ReportsPanel/>     </TabPanel>;
      default: return null;
    }
  }, []);

  return (
    <Container className={classes.root} maxWidth="xl">
     <CurrentPageInfo primary="Resumo" secondary={name}/>  
      <Box className={classes.rootHorizontal}>
        <HorizontalColoredTab
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Resume project tabs"
          className={classes.horizontalTabDivider}>
          <ColoredTab label="Descrição"    {...a11yProps(0)} />
          <ColoredTab label="Observações"  {...a11yProps(1)} />   
          <ColoredTab label="Estatísticas" {...a11yProps(2)} />
          <ColoredTab label="Atividades"   {...a11yProps(3)} />
          <ColoredTab label="Relatórios"   {...a11yProps(4)} />
        </HorizontalColoredTab>
      </Box>
      <Paper className={classes.paper} elevation={0}>
       {getActiveTab(value)} 
      </Paper>
    </Container>
  );
}
