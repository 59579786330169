import React from "react";
import {
  Card,
  Typography,
  makeStyles, CardHeader, Avatar, Box,  
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";
import FormLoadingComponent from "../../../../Components/FormLoading";
import { ShowPercent } from "../../../../Components/ShowInfos";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding:"0.2rem",
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
    [theme.breakpoints.up("xs")]: {height: "110px"},
    [theme.breakpoints.up("sm")]: {height: "140px"},
    [theme.breakpoints.up("md")]: {height: "110px"},
    [theme.breakpoints.up("lg")]: {height: "130px"},
    [theme.breakpoints.up("xl")]: {height: "110px"},  
  },
  title:{
    fontWeight: "bold",
    fontFamily: "sans-serif",
    [theme.breakpoints.up("xs")]: { fontSize: "1.3rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.4rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.2rem"},  
  },
  subtitle:{
    fontWeight: "normal",
    fontWeight: "bold",
    [theme.breakpoints.up("xs")]: { fontSize: "1.9rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.9rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "2.1rem"},  
  },
  avatar: {
    color: "white",
    [theme.breakpoints.up("xs")]: {width: theme.spacing(3), height: theme.spacing(3)},      
    [theme.breakpoints.up("sm")]: {width: theme.spacing(2), height: theme.spacing(2)},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(3), height: theme.spacing(3)},    
    [theme.breakpoints.up("lg")]: {width: theme.spacing(3), height: theme.spacing(3)},    
    [theme.breakpoints.up("xl")]: {width: theme.spacing(3), height: theme.spacing(3)},    
  },
  large: {
    [theme.breakpoints.up("xs")]: {width: theme.spacing(5), height: theme.spacing(5),},   
    [theme.breakpoints.up("sm")]: {width: theme.spacing(4), height: theme.spacing(4),},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(5), height: theme.spacing(5),},  
    [theme.breakpoints.up("lg")]: {width: theme.spacing(5), height: theme.spacing(5),},  
    [theme.breakpoints.up("xl")]: {width: theme.spacing(5), height: theme.spacing(5),},  
  },
}));

export const CardCurrentGUT = ({value}) => {
  const classes = useStyles();
  return (
      <Card elevation={0} className={classes.root}>
        <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
         avatar={ 
         <Avatar className={classes.large} style={{backgroundColor: "orange"}}>
           <Icons.Warning className={classes.avatar}/>
         </Avatar>}
         title={
          <Typography className={classes.title} component="h1" color="textPrimary">
            Nível atual
           </Typography>}
          subheader={
          <Typography style={{fontWeight: "bold"}} variant="subtitle1" color="textSecondary">
            {value?` A maior parte dos seus problemas é de ${value}`:<FormLoadingComponent/>}
          </Typography>
          
        }
        />  
      </Card>
  );
};

export const CardPercent = ({value}) => {
  const classes = useStyles();
  return (
     <Card elevation={0} className={classes.root}>
        <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
         avatar={ 
         <Avatar className={classes.large} style={{backgroundColor: "orange"}}>
           <Icons.DonutLarge  className={classes.avatar}/>
         </Avatar>}
         title={
           <Typography style={{fontWeight: "bold", marginBottom: "0.5rem"}} variant="h5">
            Problemas
           </Typography>}
          subheader={ 
            <Box>
             {value ? <ShowPercent value={value}/> :<FormLoadingComponent/>}
            </Box>
          }
        />  
      </Card>
  );
};

export const CardFeedBack = ({value}) => {
  const classes = useStyles();
  return (
     <Card elevation={0} className={classes.root}>
        <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
         avatar={ 
         <Avatar className={classes.large} style={{backgroundColor: "orange"}}>
           <Icons.Help className={classes.avatar}/>
         </Avatar>}
         title={
           <Typography style={{fontWeight: "bold"}} variant="h5" gutterBottom>
            Feedback principal
           </Typography>}
          subheader={
          <Typography style={{fontWeight: "bold" }} variant="subtitle2" color="textSecondary">
           {value ? value:<FormLoadingComponent/>}
          </Typography>}
        />  
      </Card>
  );
};

 
