import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Fab,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Card,
  CardContent,
  LinearProgress,
  ListItemText,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";

import { FormatDate, FormatRealNumber } from "../Helper/FormatValues";
import { ShowProjectStatus } from "../ShowInfos";
import api from "../../services/Api";
import { GET_RQSTINFO_PROJECT } from "../../services/options";
import { ViewTextEditor } from "../Editor";
import DefaultModal, { DefaultModalContent } from "../DefaultModal";

export const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      display: "flex", 
      alignContent: "start",
      alignItems: "start",
      [theme.breakpoints.up("xs")]: { width: 390, height: "100%"},   
      [theme.breakpoints.up("sm")]: { width: 490, height: "100%"},    
      [theme.breakpoints.up("md")]: { width: 740, height: "100%"},  
      [theme.breakpoints.up("lg")]: { width: 780, height: "100%"},  
      [theme.breakpoints.up("xl")]: { width: 900, height: "100%"},  
    },
    description: {
      minWidth: 500,
      display: 'grid',
    },
    info: {
      minWidth: 500,
      paddingLeft: 30,
    },
    divider: {
      margin: theme.spacing(0, 2, 0, 2),
    },
  })
);

export default function ViewMyProject({id, open, close}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);

  const getData = React.useCallback(async () => {
    await api(GET_RQSTINFO_PROJECT(id))
      .then((resp) => {
        const { data } = resp;
 
         setData({
          name                  : data.name,
          responsible:          data.responsible.name,
          responsibleSecretary: data.responsibleSecretary.name,
          resume:          data.resume,
          description:     data.description,
          group:           data.group.name,
          axis:            data.axis.name,
          situation:       data.status         === null ? 0: Number(data.status),
          predictedValue:  data.predictedValue === null ? 0: Number(data.predictedValue),
          executedValue:   data.executeValue   === null ? 0: Number(data.executeValue),
          initDate: data.initDate,
          deadLine: data.deadLine,
          created:  data.createDate,
          updated:  data.alterDate,
          canceled: data.canceled  === "1"? true:false,
          justification: data.justification,
         });
      })
      .catch((error) => {
        setData(null);
      });
  },[id]);

  React.useEffect(() => {
   if(open) getData()
  }, [open]);

  const handleClose = () => {
    close()
  };

  return (
    <>
    <DefaultModal
      maxWidth="md"
      open={open}
      loading={data === null}
      handleClose={handleClose}
      title={data?.name}
      actionsTitle={
        <Tooltip
        placement="top"
        title="Visualizar projeto"
        aria-label="View Project"
      >
        <Fab
          size="small"
          color="primary"
          aria-label="View"
          onClick={() => navigate(`/projects/view/${id}/resume/`)}
        >
          <Icons.AspectRatio />
        </Fab>
      </Tooltip>
      }
    >
     <DefaultModalContent dividers>
      <Grid container className={classes.main}>
       <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={{paddingLeft: "0.5rem", marginRight: "1rem"}}>
        <Grid item xs={12} style={{marginBottom: 30}}> 
          <CardResume resume={data?.resume} /> 
        </Grid>
        <Grid item xs={12}> 
         <CardDescription description={data?.description} /> 
        </Grid>
       </Grid>
       <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
        <ShowInfos data={data} />
       </Grid>
      </Grid>
     </DefaultModalContent>
   </DefaultModal> 
  </>
  );
}


const CardResume = ({ resume }) => {
  return (
    <Card style={{ width: "100%" }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{ margin: "5px 0px 5px 10px" }}
      >
        Resumo
      </Typography>
      <Divider />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom paragraph={true}>
          {resume}
        </Typography>
      </CardContent>
    </Card>
  );
};

const CardDescription = ({ description }) => {
  return (
    <Card style={{ width: "100%", minHeight: 200, maxHeight: 400 }}>
      <Typography variant="h6" style={{ margin: "5px 0px 5px 10px" }}>
        Objetivo
      </Typography>
      <Divider />
      <ViewTextEditor value={description} isTheme />
    </Card>
  );
};

const ShowInfos = ({ data }) => {
  const {
    responsibleSecretary,
    responsible,
    axis,
    group,
    situation,
    predictedValue,
    executedValue,
    initDate,
    deadLine,
    created,
    updated,
  } = data;

  const dataInfo = [
    {
      icon: <Icons.Person />,
      text: "Responsável: ",
      data: responsible,
    },
    {
      icon: <Icons.AccountBalance />,
      text: "Secretaria responsável: ",
      data: responsibleSecretary,
    },
    {
      icon: <Icons.GroupWork />,
      text:"Portifólio: ",
      data: group,
    },
    {
      icon: <Icons.ScatterPlot/>,
      text: "Programa: ",
      data:  axis,
    },
    {
      icon: <Icons.LocalAtm />,
      text: "Valor previsto: ",
      data: FormatRealNumber(predictedValue),
    },
    {
      icon: <Icons.AttachMoney />,
      text: "Valor executado: ",
      data: FormatRealNumber(executedValue),
    },
    {
      icon: <Icons.Event />,
      text: "Data inicial: ",
      data: FormatDate(initDate),
    },
    {
      icon: <Icons.EventAvailable />,
      text: "Data limite: ",
      data: FormatDate(deadLine),
    },
    {
      icon: <Icons.Update />,
      text: "Tempo estimado: ",
      data: null,
    },
    {
      icon: <Icons.Help />,
      text: "Situação: ",
      data: <ShowProjectStatus type={situation} value={0}/>,
    },
  ];

  const timeInfo = [
    {
      icon: <Icons.EventAvailable />,
      text: "Criado: ",
      data: FormatDate(created, 'Não definido'),
    },
    {
      icon: <Icons.DateRange />,
      text: "Atualizado: ",
      data: FormatDate(updated, 'Não definido'),
    },
  ];

  return (
    <>
      <List dense disablePadding>
       {dataInfo.filter(r=> r.data !== null).map((value) =>(
         <ListItem  alignItems="start" key={value.text}>
         <ListItemIcon >{value.icon}</ListItemIcon>
         <ListItemText
         style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
         primary={<Typography variant="h6" component="span">{value.text}</Typography>}
         secondary={
          <Typography
            component="span"
           // variant="body2"
            style={{marginLeft: "0.5rem" }}
            color="textSecondary"
          >
          {value.data} 
          </Typography>
        }/>
        </ListItem>
      ))}
    </List>
      <Divider style={{margin: "0.5rem 0px 0.5rem 0px"}}  variant="inset" />
       <List dense disablePadding>
        {timeInfo.map((value) => (
          <ListItem  alignItems="start" key={value.text}>
          <ListItemIcon >{value.icon}</ListItemIcon>
          <ListItemText
          style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
          primary={<Typography variant="h6" component="span">{value.text}</Typography>}
          secondary={
           <Typography
             component="span"
            // variant="body2"
             style={{marginLeft: "0.5rem" }}
             color="textSecondary"
           >
           {value.data} 
           </Typography>
         }/>
         </ListItem>
       ))}
     </List>
    </>
  );
};

