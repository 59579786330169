import {
    SupervisorAccount,
    AccountBalance,
    WebAsset,
    MenuBook,
    GroupWork,
    ScatterPlot,
    Person,
    Notifications,
    FolderOpen,
  } from '@material-ui/icons';

export const menuAdmin = [
    { name: 'Usuários',     to: '/administrator/user'     ,    icon: <SupervisorAccount/>},
    // { name: 'Niveis de acesso',  to: '/administrator/userlevel/', icon: <SupervisedUserCircle/>},
    { name: 'Secretarias',  to: '/administrator/secretary',    icon: <AccountBalance/>},
    { name: 'Portifólios',  to: '/administrator/group'    ,    icon: <GroupWork/>},
    { name: 'Programas',    to: '/administrator/axis'     ,    icon: <ScatterPlot/>},
    //{ name: 'Notificação',  to: '/administrator/notification', icon: <Notifications/>},
  ];

  export const menuProjects = [
    { name: 'Projetos',     to: '/projects'        ,  icon: <WebAsset/> },
    { name: 'Resumo',       to: '/projects/resume' ,  icon: <MenuBook/> },
    { name: 'Base de dados',to: '/projects/saved'  ,  icon: <FolderOpen/> },
  ];

  export const menuUserPainel = [
    { name: 'Perfil',         to: '/userpanel/profile'      , icon: <Person/> },
    { name: 'Meus projetos',  to: '/userpanel/myproject'    , icon: <WebAsset/> },
    { name: 'Minhas etapas', to: '/userpanel/mytask'       , icon: <MenuBook/> },
    { name: 'Notificações',   to: '/userpanel/notification' , icon: <Notifications/> },
  ];