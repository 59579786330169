import React from "react";
import {
  createStyles,
  makeStyles,
  Grid,
  Typography,
  Divider,
  Paper,
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";
import ViewList from "../ViewList";
 
// Gráfico em pizza Plano de Governo x Projeto de Rotina
export const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: 5,
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {maxWidth: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {maxWidth: "100%", height: 300},
    [theme.breakpoints.up("md")]: {maxWidth: "100%", height: 300},
    [theme.breakpoints.up("lg")]: {maxWidth: "100%", height: 300},
    [theme.breakpoints.up("xl")]: {maxWidth: "100%", height: 350},
    paddingTop:"1rem",
  }
})
);

const GET_INFO = (id) => {
  return {
    method: "get",
    url: `dashboard/groupProgress.php?id=${id}`,
  };
};

function ViewChart({data, handleClick}){
 const {projectRoutine, projectGroup, groupName} = data
 const series = [projectRoutine, projectGroup]
 const options = {
  chart:{
    events: {
      dataPointSelection: (event, chartContext, config) => { 
        handleClick(config.w.config.labels[config.dataPointIndex])
        }
    },
  },
    labels: ["Projetos de rotina", groupName],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      offsetY: 0,
    },
  }
  return <ReactApexChart options={options} series={series} type="donut" width="100%" height="100%"
/>
}

const init ={
  projectRoutine: 0,
  projectGroup:   0,
  groupName:     "",
}

export default function AmountTypeProject({group}) {
  const classes = useStyles();
  const [data, setData] = React.useState(init)
  const [view, setView] = React.useState(null)

  const handleClose =()=>{
    setView(null)
  }

  const handleClick =(type)=>{
    if (type === group.name){
      setView({
        name: "",
        group: group.id,
        axis: null,
        responsible: null,
        responsibleSecretary: null,
        status: null,
        type: {
          routine: false,
          governmentPlan: true,
        },
      })
    }else{
      setView({
        name: "",
        group: null,
        axis: null,
        responsible: null,
        responsibleSecretary: null,
        status: null,
        type: {
          routine: true,
          governmentPlan: false,
        },
      })
    }
  }

  const updateData = async () => {
    if (group.id !== null) await api(GET_INFO(group.id)).then(({data}) => {
      if (data === null) return
        //{"rotinas":"5","name":"Plano de Governo 2021 - 2024","groupQtd":"2"}
        const projectRoutine = Number(data.rotinas)  ? Number(data.rotinas) : 0;
        const projectGroup   = Number(data.groupQtd) ? Number(data.groupQtd): 0;
        const groupName      = data.name ? data.name : "";

        setData({projectRoutine, projectGroup, groupName})
      }).catch(e=>{
        setData(init)
      });
  };

  React.useEffect(() => {
    updateData();
  }, [group.id]);

 return (
 <>  
 <ViewList search={view} handleClose={handleClose} title={`${group.name} x Projeto de Rotina`}/>
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography variant="h6" gutterBottom align="center"> {group.name} x Projeto de Rotina </Typography>
   <Divider />
  </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart data={data} handleClick={handleClick}/>
  </Grid>
 </Grid>
 </>  
 );
}
