import React from "react";
 
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { SelectApi } from "./../../../../Components/SelectsApi";
import { GET_RESPONSIBLESECRETARY, GET_SECRETARY } from "../../../../services/options";
import { NumberFormatCustom } from "./../../../../Components/Helper/FormatValues";
import { CreateProjectContext } from "..";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import { TextEditor } from "../../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";
import { CustomDatePicker } from "../../../../Components/CustomDatePicker";
 
const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const initialValues = {
  name: "",
  initDate: null,
  deadLine: null,
  predictedValue: undefined,
  description: "<p></p>",
  priority: null,
  responsible: null,
  responsibleSecretary: null,
};

export default function DialogTask({submit, rowData, open, setOpen, title }) {
  const {projectValue} = React.useContext(CreateProjectContext)

  const projectInitDate = projectValue.initDate
  const projectDeadLine = projectValue.deadLine
 
  const projectPredictedValue = projectValue.predictedValue 

  const initial = rowData || initialValues;
  const init = {...initial,  projectInitDate, projectDeadLine, projectPredictedValue}


  const validationSchema = Yup.object({
    name: Yup.string().required("Obrigatório")
      .min(6, "O nome deve ter pelo menos 6 caracteres")
      .max(200, "Limite maximo de 200 caracteres"),
  
     // projectInitDate: Yup.date().required("Obrigatório").nullable(),
     // projectDeadLine: Yup.date().required("Obrigatório").nullable(),
     // projectPredictedValue: Yup.number().required("Obrigatório").nullable(),
      
  
      initDate: Yup.date().required("Obrigatório").nullable().typeError("Data inválida"),
      deadLine: Yup.date().required("Obrigatório").nullable().typeError("Data inválida"),
      // deadLine: Yup.date().min(
      // Yup.ref('initDate'),
      //   "A data final não pode ser anterior à data de inícial"
      // ).required("Obrigatório").nullable(),
  
    predictedValue: Yup.number().required("Obrigatório"),
  
 //   description: Yup.string().optional(),
 //   priority: Yup.object().shape().optional(),
    responsibleSecretary: Yup.object().shape().required("Obrigatório").nullable(),
    responsible: Yup.object().shape().required("Obrigatório").nullable(),
  });

  return (
    <DefaultModal open={open} handleClose={()=>setOpen(false)} title={title} disableBackdropClick={true}>
      <Formik
        initialValues={init}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props}/>}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values: {
      name,
      responsible,
      responsibleSecretary,
      initDate,
      deadLine,
      predictedValue,
      description,
      projectInitDate,
      projectDeadLine,
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = props;
 
  return ( 
    <form onSubmit={handleSubmit} noValidate> 
      <DefaultModalContent dividers >
        <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="etapa"
            name="name"
            autoFocus
            inputProps={{ autoComplete: 'off' }} 
            onChange={handleChange}
            value={name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

       <Grid item xs={12} style={{display: "flex"}}> 
       <Grid item xs={6} style={{ display: "grid" }} >
          <Grid item xs={12} style={{ maxWidth: "85%" }}>
          <CustomDatePicker
           fullWidth
           id="initDate"
           name="initDate"
           label="Data inicial"
           minDate={projectInitDate}
           maxDate={deadLine||projectDeadLine}
           onChange={(value) =>{
           setFieldValue("initDate",  value || null)}}
           value={initDate}
           error={touched.initDate && Boolean(errors.initDate)}
           helperText={touched.initDate && errors.initDate}
          />
          </Grid>

          <Grid item xs={12} style={{ maxWidth: "85%" }}>
           <CustomDatePicker
            fullWidth
            id="deadLine"
            name="deadLine"
            label="Data limite"
            margin="normal"
            minDate={initDate||projectInitDate}
            maxDate={projectDeadLine}
            onChange={(value) =>{
            setFieldValue("deadLine",  value || null)}}
            value={deadLine}
            error={touched.deadLine && Boolean(errors.deadLine)}
            helperText={touched.deadLine && errors.deadLine}
           />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="predictedValue"
            label="Valor previsto"
            name="predictedValue"        
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              autoComplete: 'off',
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange}
            value={predictedValue}
            error={touched.predictedValue && Boolean(errors.predictedValue)}
            helperText={touched.predictedValue && errors.predictedValue}
          />
        </Grid>
        </Grid>

        <Grid item xs={12} style={{marginBottom: "0.6rem"}}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {
              setFieldValue("description", convertToHTML(event.getCurrentContent()));
            }}
            value={description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) =>
             { setFieldValue("responsibleSecretary", value || null)
               setFieldValue("responsible", null)}
            }
            value={responsibleSecretary}
            config={GET_SECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
            error={
              touched.responsibleSecretary &&
              Boolean(errors.responsibleSecretary)
            }
            helperText={
              touched.responsibleSecretary && errors.responsibleSecretary
            }
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            disabled={responsibleSecretary === null}
            onChange={(event, value) =>{
              (value?.id !== null || value === null ? setFieldValue("responsible", value || null):
              setFieldValue("responsible", null))
            }}
            value={responsible}
            nullMessage={"Nenhum usuário nessa secretaria!"}
            config={GET_RESPONSIBLESECRETARY(responsibleSecretary?.id)}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Responsável",
            }}
            error={touched.responsible && Boolean(errors.responsible)}
            helperText={touched.responsible && errors.responsible}
          />
        </Grid>

        {/* <Grid item xs={12} style={{ maxWidth: "40%" }}>
          <SelectApi
            id="priority"
            name="priority"
            label="Prioridade"
            onChange={(e, value) => {
              setFieldValue("priority", value || null);
            }}
            value={priority}
            config={GET_PRIORITY_TASK()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Prioridade",
            }}
            error={touched.priority && Boolean(errors.priority)}
            helperText={touched.priority && errors.priority}
          />
        </Grid> */}
        </Grid>
        </DefaultModalContent>
        <DefaultModalActions style={{marginRight:"0.5rem"}}>
         <ButtonSuccess
         variant="contained"
         color="primary"
         type="submit"
         success={false}
         loading={isSubmitting}
        > Confirmar
         </ButtonSuccess>
        </DefaultModalActions>  
    </form>
  );
};

DialogTask.defaultProps = {
  hookSubmit: (value) => {
    return true;
  },
  title: "Nova etapa",
  titebutton: "editar",
};
