import React from "react";
import {
  Button,
  Grid,
  Container,
  createStyles,
  makeStyles,
  Paper,Typography
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import { GET_TABLE_PROJECT } from "../../../services/options";
import Table from "./Table";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import useAxios from "../../../Context/Hooks/useAxios";

import FilterProject from "../../../Components/Screens/FilterProject";
import { Search } from "@material-ui/icons";
import { UserContext } from "../../../Context/UserContext";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    main: {
      display: "grid",
    },
    paper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1),
      borderRadius: "5px",
    },
    table:{
      marginTop: 5,
      borderRadius: "5px",
      [theme.breakpoints.up("xs")]:  { minHeight: 450},        
      [theme.breakpoints.up("sm")]:  { minHeight: 450},  
      [theme.breakpoints.up("md")]:  { minHeight: 450},   
      [theme.breakpoints.up("lg")]:  { minHeight: 450},  
      [theme.breakpoints.up("xl")]:  { minHeight: 650},   
    },
  })
);

const initialValues = {
    name: "",
    group: null,
    axis: null,
    responsible: null,
    responsibleSecretary: null,
    status: null,
    type: {
      routine: false,
      governmentPlan: false,
    },
  }

export default function MainProjects() {
  const classes = useStyles();
  const navigate = useNavigate()
  const { privilege } = React.useContext(UserContext);
  const {buffer, loading, error, request} = useAxios();
  const [search, setSearch] = React.useState(initialValues);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
 
  const updateData = async () => {
    await request(GET_TABLE_PROJECT(search)).then().catch(e=>{
      enqueueSnackbar(`Erro ao atualizar lista !!!`, { variant: "warning" });
    })
  };

  React.useEffect(() => {
       updateData();
  }, [search]);

  return (
  <div className={classes.root}>
   <FilterProject drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} search={search}  setSearch={setSearch}/>
   <Container className={classes.main} maxWidth="xl">
    <Grid item xs={12}>
     <CurrentPageInfo primary="Portifólio de projetos"/>
    </Grid>

    <Grid item xs={12}>
     <Paper className={classes.paper}>
      <Grid item xs={8}>
       <Button variant="contained" color="primary"  onClick={()=> setDrawerOpen(!drawerOpen)}>
        <Typography >Filtro</Typography>
         <Search style={{marginLeft: 25}}/>
        </Button>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "end",   }}>
       <Button variant="contained" color="primary" 
        className={classes.button}  
        disabled={privilege.cityHall}
        onClick={()=>navigate(`/projects/new`)}>
         Novo Projeto
       </Button>
      </Grid>
     </Paper>
    </Grid>
    <Grid item xs={12}>
    <Paper className={classes.table}>
     <Table data={buffer} error={error} load={loading}/> 
    </Paper>
    </Grid>
   </Container>
  </div>
  );
}