import React from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { Formik } from "formik";
import * as Yup from "yup";

import { convertToHTML } from "draft-convert";
import { GET_GROUP, POST_INSERT_AXIS } from "../../../services/options";
import { SelectApi } from "../../../Components/SelectsApi";
import { useSnackbar } from "notistack";
import api from "../../../services/Api";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import { TextEditor } from "../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const initialValues = {
  name: "",
  description: "<p></p>",
  group: null,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
 // description: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
  group: Yup.object().shape().required("Obrigatório").nullable(),
});

export default function NewAxis({open, setOpen, updateData }) {
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
  const buffer = {
    name: values.name,
    description: values.description,
    groupId: values.group.id
  }
   await api(POST_INSERT_AXIS(buffer)).then(r=>{
     const {data} = r;
     if (data.status ==="success"){
      updateData()
      setSubmitting(false)
      enqueueSnackbar("Programa criado !!!", { variant: "success" });
      setOpen(false);
     }else{
      setSubmitting(false)
      enqueueSnackbar(`Erro ao criar programa`, { variant: "Error" });
     }

   }).catch(e=>{
    setSubmitting(false)
    enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
   })
};
 
  return (
    <DefaultModal open={open} handleClose={()=>setOpen()} title="Novo programa">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}>
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
        <Grid container  className={classes.root} spacing={3}> 
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            fullWidth
            disabled={values.routine === "true"}
            id="group"
            name="group"
            onChange={(e, value) => {
              setFieldValue("group", value || null);
            }}
            value={values.group}
            config={GET_GROUP}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Portifólio",}}
            error={touched.group && Boolean(errors.group)}
            helperText={touched.group && errors.group}
          />
        </Grid>

        <Grid item xs={12}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {
              setFieldValue("description", convertToHTML(event.getCurrentContent()));
            }}
            data={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>
        </Grid>
        </DefaultModalContent>
        <DefaultModalActions style={{marginRight: "0.5rem"}}>
         <ButtonSuccess
          style={{width: 120}}
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
         > Criar
         </ButtonSuccess>
       </DefaultModalActions>
    </form>
  );
};
