import { createTheme } from '@material-ui/core/styles';
import defaultTheme from "./default";
const { breakpoints } = defaultTheme

const overrides = {
  typography: {
    h1: {
   //   fontSize: "2.5rem",
      [breakpoints.up("xs")]: {fontSize: "4.6rem",}, //0
      [breakpoints.up("sm")]: {fontSize: "4.7rem",}, //600
      [breakpoints.up("md")]: {fontSize: "4.8rem",}, //960,
      [breakpoints.up("lg")]: {fontSize: "4.9rem",}, //1366,
      [breakpoints.up("xl")]: {fontSize: "5.0rem",}, //1920  fontSize: "6rem"
    },
    h2: {
     // fontSize: "2rem",
      [breakpoints.up("xs")]: {fontSize: "2.0rem",}, //0
      [breakpoints.up("sm")]: {fontSize: "2.05rem",}, //600
      [breakpoints.up("md")]: {fontSize: "2.1rem",}, //960,
      [breakpoints.up("lg")]: {fontSize: "2.15rem",}, //1366,
      [breakpoints.up("xl")]: {fontSize: "2.2rem",}, //1920  fontSize: "3.75rem"
    },
    h3: {
    //  fontSize: "1.64rem",
      [breakpoints.up("xs")]: {fontSize: "2.6rem",}, //0
      [breakpoints.up("sm")]: {fontSize: "2.7rem",}, //600
      [breakpoints.up("md")]: {fontSize: "2.8rem",}, //960,
      [breakpoints.up("lg")]: {fontSize: "2.9rem",}, //1366,
      [breakpoints.up("xl")]: {fontSize: "3rem",}, //1920  fontSize: "3rem"
    },
    h4: {
      //fontSize: "1.5rem",
      [breakpoints.up("xs")]: {fontSize: "2.035rem",}, //0
      [breakpoints.up("sm")]: {fontSize: "2.045rem",}, //600
      [breakpoints.up("md")]: {fontSize: "2.055rem",}, //960,
      [breakpoints.up("lg")]: {fontSize: "2.065rem",}, //1366,
      [breakpoints.up("xl")]: {fontSize: "2.070rem",}, //1920  fontSize: "2.125rem"
    },
    h5: {
      //fontSize: "1.285rem",
      [breakpoints.up("xs")]: {fontSize: "1.1rem",}, //0
      [breakpoints.up("sm")]: {fontSize: "1.2rem",}, //600
      [breakpoints.up("md")]: {fontSize: "1.3rem",}, //960,
      [breakpoints.up("lg")]: {fontSize: "1.4rem",}, //1366,
      [breakpoints.up("xl")]: {fontSize: "1.5rem",}, //1920  fontSize: "1.5rem"
    },
    h6: {
   //   fontSize: "1.142rem",
      [breakpoints.up("xs")]: {fontSize: "0.95rem",}, //0
      [breakpoints.up("sm")]: {fontSize: "1.00rem",}, //600
      [breakpoints.up("md")]: {fontSize: "1.05rem",}, //960,
      [breakpoints.up("lg")]: {fontSize: "1.10rem",}, //1366,
      [breakpoints.up("xl")]: {fontSize: "1.15rem",}, //1920  fontSize: "1.25rem"
    },
    button: {
      //   fontSize: "1.142rem",
      [breakpoints.up("xs")]: {fontSize: "0.70rem",}, //0
      [breakpoints.up("sm")]: {fontSize: "0.75rem",}, //600
      [breakpoints.up("md")]: {fontSize: "0.80rem",}, //960,
      [breakpoints.up("lg")]: {fontSize: "0.85rem",}, //1366,
      [breakpoints.up("xl")]: {fontSize: "0.87rem",}, //1920  fontSize: "1.25rem"
    },
 
  },
};

const themes = {
  default:  createTheme({ ...defaultTheme, ...overrides }),
};

export default  themes;