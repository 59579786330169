import React from "react";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  DialogContentText, Grid
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { POST_UPDATE_PROJECT } from "../../../services/options";
import api from "../../../services/Api";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
 
import { TextEditor } from "../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";
import { SelectApi } from "../../SelectsApi";
import { GET_RESPONSIBLESECRETARY, GET_SECRETARY } from "../../../services/options";
import { ButtonSuccess } from "../../ButtonSuccess";

const initialValue = {
  description: "<p></p>",
  responsible: null,
  responsibleSecretary: null,
};
 
 const validationSchema = Yup.object().shape({
  justification: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
  responsibleSecretary: Yup.object().shape().required("Obrigatório").nullable(),
  responsible: Yup.object()
  .shape({
   // id: Yup.string().required("Inválido"),
  })
  .nullable()
  .required("Obrigatório"),
 });

 const useStyles = makeStyles((theme) =>
 createStyles({
   root: {
     [theme.breakpoints.up("xs")]: { maxWidth: 380 },
     [theme.breakpoints.up("sm")]: { maxWidth: 450 },
     [theme.breakpoints.up("md")]: { maxWidth: 500 },
     [theme.breakpoints.up("lg")]: { maxWidth: 500 },
     [theme.breakpoints.up("xl")]: { maxWidth: 550 },
   },
 })
);

export default function DialogTaskSend({open, taskId, setOpen, updateData}) {
   
    const { enqueueSnackbar } = useSnackbar();

    const submit = async(values, { setSubmitting }) => {
     await api(POST_UPDATE_PROJECT(values)).then(r=>{
       const {data} = r;
       if(data.status === "success"){
        updateData()
        setOpen(false);
        enqueueSnackbar("Etapa encaminhada !!!", { variant: "success" });
       }else{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao encaminhar etapa`, { variant: "Error" })
       }
      }).catch(r=>{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao encaminhar etapa`, { variant: "Error" })
      })
    }
    
    const closeModal =()=>{
      setOpen(false)
    }
    
    return (
      <DefaultModal
        open={open}
        handleClose={closeModal}
        title="Encaminhar etapa"
      >
       <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={submit}
       >
        {(props) => <Forms {...props}/>}
      </Formik> 
    </DefaultModal>
  );
}



const Forms = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
      <DialogContentText >
        <Typography>
         Encaminhe a etapa!
        </Typography>
       </DialogContentText>

       <Grid container className={classes.root} spacing={3}>  
        <Grid item xs={12}>
         <SelectApi
          id="responsibleSecretary"
          name="responsibleSecretary"
          onChange={(event, value) =>
             { setFieldValue("responsibleSecretary", value || null)
               setFieldValue("responsible", null)}
            }
          value={values.responsibleSecretary}
          config={GET_SECRETARY()}
          textFieldProps={{
          fullWidth: true,
          size: "small",
          variant: "outlined",
          label: "Secretaria responsável",
         }}
         error={touched.responsibleSecretary && Boolean(errors.responsibleSecretary)}
         helperText={touched.responsibleSecretary && errors.responsibleSecretary}
        />
        </Grid>  

       <Grid item xs={12}>
        <SelectApi
         id="responsible"
         name="responsible"
         disabled={values.responsibleSecretary === null}
         onChange={(event, value) =>{
         (value?.id !== null || value === null ? setFieldValue("responsible", value || null):
         setFieldValue("responsible", null))
         }}
         value={values.responsible}
         nullMessage={"Nenhum usuário nessa secretaria!"}
         config={GET_RESPONSIBLESECRETARY(values.responsibleSecretary?.id)}
         textFieldProps={{
          fullWidth: true,
          size: "small",
          variant: "outlined",
          label: "Responsável",
         }}
         error={touched.responsible && Boolean(errors.responsible)}
         helperText={touched.responsible && errors.responsible}
        />
        </Grid>

        <Grid item xs={12}>
        <TextEditor
         id="justification"
         name="justification"
         label="Justificativa"
         onChange={(event) => {setFieldValue("justification", convertToHTML(event.getCurrentContent()))}}
         value={values.justification}
         error={touched.justification && Boolean(errors.justification)}
         helperText={touched.justification && errors.justification}
        />
        </Grid>
        
       </Grid>
      </DefaultModalContent>
      <DefaultModalActions >
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
          disabled
        >
          Confirmar
        </ButtonSuccess>
      </DefaultModalActions>
    </form>
  );
};
