import React from 'react'
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';
import moment from 'moment';

export function truncarText(texto,limite){
  if(texto.length>limite){ 
      limite--;
     let last = texto.substr(limite-1,1);
      while(last !==' ' && limite > 0){
          limite--;
          last = texto.substr(limite-1,1);
      }
      last = texto.substr(limite-2,1);
      if(last === ',' || last === ';'  || last === ':'){
           texto = texto.substr(0,limite-2) + '...';
      } else if(last === '.' || last === '?' || last === '!'){
           texto = texto.substr(0,limite-1);
      } else {
           texto = texto.substr(0,limite-1) + '...';
      }
  }
  return texto;
}

export function MaskCel(props) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[0-9]/, /\d/,')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

MaskPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


export function MaskPhone(props) {
    const { inputRef, mask, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[0-9]/, /\d/,')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        // showMask
      />
    );
  }
 
  MaskPhone.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

export function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        //thousandSeparator={true}
        //allowedDecimalSeparators={['.',',']}
        decimalSeparator=","
        thousandSeparator="."
        
        decimalScale={2}
        isNumericString
        prefix="R$ "
      />
    );
  }

  export function FormatRealNumber(value){
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return nf.format(value);
  }

  export function FormatDate(value, message){
    return value !== "0000-00-00"? moment(value).format('DD/MM/YYYY'):message;
  }

  FormatDate.defaultProps ={
    value: new Date(),
    message: '',
  }