import React from "react";
import { useSnackbar } from "notistack";
import useAxios from "../../../../../Context/Hooks/useAxios";
import { GET_TABLE_PROJECT } from "../../../../../services/options";
import FormLoadingComponent from "../../../../../Components/FormLoading";
import Table from "./Table";
import DefaultModal, { DefaultModalContent } from "../../../../../Components/DefaultModal";

export default function ViewList({search, handleClose, title}){
    const { buffer, loading, error, request } = useAxios();
    const { enqueueSnackbar } = useSnackbar();

    const updateData = async () => {
        await request(GET_TABLE_PROJECT(search)).then()
        .catch(e=>{
          enqueueSnackbar("Erro ao atualizar lista", { variant: "Error" });
        });
      };

      React.useEffect(() => {
         if(search !== null) updateData();
      }, [search]);

      return(
        <DefaultModal
         title={title}
         loading={loading}
         open={search !== null}
         handleClose={() => handleClose()}
         maxWidth="lg"
        >
         <DefaultModalContent> 
           {!loading ? <Table data={buffer} updateData={updateData} error={error} load={loading}/>:<FormLoadingComponent/>}
         </DefaultModalContent>
        </DefaultModal>
        )
    }