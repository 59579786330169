import React from "react";
import { Routes, Route } from "react-router-dom";
import { LayoutContext } from "./../../Components/Layout/LayoutMaster";
import { menuProjects } from "./../../Components/Layout/LayoutMaster/menus";
import MainProjects from './Main' 
import NewProject from './NewProject' 
import DataBaseProjects from "./OldProjects";
import ResumeProjects from "./Resume";

export default function Projects() {
  const {setShowLayout, setListMenu,} = React.useContext(LayoutContext);

  React.useEffect(()=>{
    setShowLayout(true)
    setListMenu(menuProjects)
  },[])

  return (
        <Routes>
          <Route path="/"        element={<MainProjects/>} />
          <Route path="/new"     element={<NewProject/>} />
          <Route path="/resume"  element={<ResumeProjects/>} />
          <Route path="/saved"   element={<DataBaseProjects/>} />
        </Routes>
  );
};