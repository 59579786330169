import React from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { LinearProgress, Paper } from "@material-ui/core";
import themes from "../../theme";

const theme = themes.default;

const myTheme = createTheme({...themes.default,
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        minHeight:'100%',
        maxHeight: "1080px",
        overflowY: 'scroll',
        [theme.breakpoints.up("xs")]: { fontSize: "1rem", },
        [theme.breakpoints.up("sm")]: { fontSize: "1rem", },
        [theme.breakpoints.up("md")]: { fontSize: "0.8rem", },
        [theme.breakpoints.up("lg")]: { fontSize: "0.9rem", }, 
        [theme.breakpoints.up("xl")]: { fontSize: "1rem", },
      },
    },
    MUIDataTableHeadCell: {
      root: {
       padding: "0.2rem 0.6rem 0.2rem 0.6rem",
       height: "52px",  
      },
    },
    MuiTableRow: {
      root: {
       color: "inherit",
       height: "12px", //Working..
       display: "table-row",
       outline: "none",
       verticalAlign: "middle",
      },
    },
   MUIDataTableBodyCell: {
    root: {
     padding: 7,
     cursor: "pointer",
     [theme.breakpoints.up("xs")]: { fontSize: "1rem", },
     [theme.breakpoints.up("sm")]: { fontSize: "1rem", },
     [theme.breakpoints.up("md")]: { fontSize: "0.8rem", },
     [theme.breakpoints.up("lg")]: { fontSize: "0.85rem", }, 
     [theme.breakpoints.up("xl")]: { fontSize: "0.9rem", },
    },
   },
   
  },
});

export default function CustomTable(props) {
  const { data, columns, opt, error, load, elevation, ...arg } = props;

  const aux = (data !== null && data !== undefined) ? data: []

  const options = {
    filterType: "dropdown",
    responsive: "vertical", //stackedresponsive modes "vertical", "standard", and "simple" for mobile/tablet devices.
    elevation: 0,

    pagination: aux.length >= 10,
    
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    selectableRows: "single",
    //indexColumn: "id",

    textLabels: {
      body: {
        noMatch: load  ? "Carregando!!!" : error !== false? error: 'Sem resultados',
        toolTip: "Organizar",
        columnHeaderTooltip: (column) => `Organizar ${column.label}`,
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Resultados por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Procurar",
        downloadCsv: "Download em Excel",
        print: "Imprimir",
        viewColumns: "Mostrar colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "Todos",
        title: "Filtrar",
        reset: "Resetar",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Ocultar",
      },
      selectedRows: {
        text: "linhas Selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linha selecionada",
      },
    },
    ...opt,
  };
  return (
    <>
    {load && <LinearProgress style={{zIndex:100, marginTop:-4}}/>}
    <Paper elevation={elevation} style={{height: "100%", borderRadius: "0.5rem",}}>
     <MuiThemeProvider theme={myTheme}>
      <MUIDataTable data={aux} columns={columns} options={options} {...arg} /> 
     </MuiThemeProvider>
    </Paper>
    </>
  );
}

CustomTable.defaultProps = {
  title: "",
  elevation: 2,
  data: [],
  load: false,
  error: false,
};

CustomTable.prototype = {};
