import React from "react";
import { useNavigate } from "react-router-dom";

import CustomTable from "../../../Components/CustomTable";
import { ShowProjectStatus} from "../../../Components/ShowInfos";
import { ShowPercent } from "../../../Components/ShowInfos";
import { FormatDate, FormatRealNumber, truncarText } from "../../../Components/Helper/FormatValues";
import { Typography } from "@material-ui/core";
 
export default function ReadTable({data, error, load}) {
  const navigate = useNavigate();
  const options = {
    onRowClick: (rowData, rowState) => navigate(`/projects/view/${rowData[0]}/resume/`),
  
  };
  return (
      <CustomTable data={data} columns={columns} opt={options} error={error} load={load} elevation={0}/>
  );
}

const columns = [
  { name: "projectId", options: { display: "false" } },
  { name: "projectName",
    label: "Projeto",
    options: {filter: true, sort: true, //style={{ cursor: "pointer" }}
      customBodyRender: (value) => <Typography style={{fontWeight: "bold", fontSize: 13}}>{truncarText(value, 75)}</Typography>   ,
     } 
  },
  { name: "projectResponsibleSecretary",
    label: "Secretaria",
    options: { filter: true, sort: true, width: 100, },
  },
  { name: "projectResponsible",
    label: "Responsável",
    options: { filter: true, sort: true, },
  },
  { name: "progress",
    label: "Progresso",
    options: { filter: true, sort: true,
     customBodyRender: (value) => <ShowPercent value={Number(value)}/>
  }
  },
  {
    name: "status",
    label: "Situação",
    options: { filter: true, sort: true,
      customBodyRender: (value) => 
      <ShowProjectStatus type={Number(value.type)} value={Number(value.value)}/>
  }
  },
  { name: "predictedValue",
    label: "Valor Previsto",
    options: {filter: true, sort: true,
      customBodyRender: (value) => FormatRealNumber(value)
    },
  },
  { name: "executeValue",
    label: "Valor Executado",
    options: { filter: true, sort: true, 
      customBodyRender: (value) => FormatRealNumber(value) 
    },
  },
  { name: "initDate",
    label: "Data inicial",
    options: { filter: true, sort: true, 
     customBodyRender: (value) => FormatDate(value) 
    },
  },
  { name: "deadLine",
    label: "Data limite",
    options: { filter: true, sort: true, 
     customBodyRender: (value) => FormatDate(value) 
    },
  },
];

