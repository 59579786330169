import React from "react";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { SelectApi } from "./../../SelectsApi";
import { TextEditor } from "./../../Editor";
import { GET_PRIORITY_TASK, GET_RESPONSIBLESECRETARY, GET_RQSTFORMINFO_TASK, GET_SECRETARY, GET_STATUS_TASK, POST_UPDATE_TASK } from "../../../services/options";
import { NumberFormatCustom } from "../../Helper/FormatValues";
import api from "../../../services/Api";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../DefaultModal";
import ConfirmEdit from "./ConfirmEdit";
import { ButtonSuccess } from "../../ButtonSuccess";
import { CustomDatePicker } from "../../CustomDatePicker";
 
const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const validationSchema = Yup.object({
  name: Yup.string().required("Obrigatório")
    .min(6, "O nome deve ter pelo menos 6 caracteres")
    .max(200, "Limite maximo de 200 caracteres"),
  // description: Yup.string()
  //   .required("Obrigatório")
  //   .min(8, "Obrigatório")
  //   .max(2000, "Limite maximo de 2000 caracteres"),
  initDate: Yup.date().required("Obrigatório").nullable().typeError("Data inválida"),
  deadLine: Yup.date().min(
    Yup.ref('initDate'),
    "A data final não pode ser anterior à data de inícial"
  ).required("Obrigatório").nullable().typeError("Data inválida"),

  predictedValue: Yup.number().required("Obrigatório").min(0, "Valor minimo").default(0),
  priority: Yup.object().shape().required("Obrigatório").nullable(),
  
  responsibleSecretary: Yup.object().shape().required("Obrigatório").nullable(),
  responsible: Yup.object()
  .shape({
   // id: Yup.string().required("Inválido"),
  })
  .nullable()
  .required("Obrigatório"),

  status: Yup.object().shape({
    id: Yup.number().required(),
    name: Yup.string().required(),
  }).required("Obrigatório").nullable(),

  executedDate: Yup.date().when("status.id", {
    is: 3,
    then: Yup.date().required("Obrigatório").nullable(),
  }),
  executedValue: Yup.number().when("status.id", {
    is: 3,
    then: Yup.number().required("Obrigatório").nullable(),
  }),
});

export default function DialogTaskAdmin({taskId, handleUpdate, dialogEdit, setDialogEdit,}) 
{
  const [editData, setEditData] = React.useState(null)
  const [openJustification, setopenJustification] = React.useState(false)

  const [init, setInit] = React.useState(null)

  const rqstInfo = async ()=>{
    await api(GET_RQSTFORMINFO_TASK(taskId)).then((resp) => {
      const  data = resp.data[0];
    setInit({
        taskId        : data.taskId,
        name          : data.name,
        initDate      : data.initDate,
        deadLine      : data.deadLine,
        predictedValue:  parseFloat(data.predictedValue, 2),
        description   : data.description,
        responsibleSecretary: data.responsibleSecretary,
        responsible   : data.responsible,
        priority      : data.priority,
        executedDate  : data.executeDate === "0000-00-00" ? '':data.executeDate,
        executedValue : data.executeValue === "0.00"? '':parseFloat(data.executeValue, 2),
        situation     : data.situation,
        status        : data.status,
        canceled      : data.canceled === '1'? true: false,
        stoped        : data.stoped   === '1'? true: false,
      })
    }).catch()
  }

  React.useEffect(()=>{
  if(dialogEdit === true && init === null) rqstInfo()
  },[dialogEdit])

  const submit = async (values, { setSubmitting }) => {
   const buffer ={
    taskId:                 values.taskId,
    name:                   values.name,
    initDate:               values.initDate,
    deadLine:               values.deadLine,
    predictedValue:        !values.predictedValue?0:values.predictedValue,
    description:            values.description,
    responsibleSecretaryId: values.responsibleSecretary.id,
    responsibleId:          values.responsible.id,
    priorityId:             values.priority.id,
    executedDate:           values.executedDate === undefined? '0':values.executedDate,
    executedValue:         !values.executedValue?0:values.executedValue,
    situation :             values.situation,
    statusId:               values.status.id,
    canceled: 0,
    stoped  : 0,
    justification: "",
  }

  setEditData(buffer)
  setopenJustification(true)
  setSubmitting(false)

    // await api(POST_UPDATE_TASK(buffer)).then(r => {
    //   const {data} = r;
    //   if(data.status === "success"){
    //     handleUpdate()
    //     setSubmitting(false);
    //     handleClose()
    //     enqueueSnackbar("etapa atualizada !!!", { variant: "success" });
    //   }else{
    //     setSubmitting(false);
    //     enqueueSnackbar(`Error ao atualizar etapa!!!`, {variant: "error"});
    //    }
    //    })
    //    .catch(error => {
    //      setSubmitting(false);
    //      enqueueSnackbar(`Error ao atualizar etapa ${error?.message} !!!`, {variant: "error"});
    //    });
   };

   const handleClose =()=>{
     setDialogEdit(false)
     setInit(null)
   }

 

  return (
  <>
    {editData && <ConfirmEdit open={openJustification} setOpen={setopenJustification} updateData={()=>{handleClose() 
                                                                                                       handleUpdate()}} editData={editData}/>}
     
    <DefaultModal 
     open={dialogEdit} 
     handleClose={handleClose} 
     title="Atualizar etapa"
     loading={init === null}>
     <Formik
     initialValues={init}
     validationSchema={validationSchema}
     onSubmit={submit}>
     {(props) => <Forms setOpen={handleClose} {...props} />}
     </Formik>
    </DefaultModal>
  </>
  );
}
const Forms = (props) => {
  const classes = useStyles();
  const {
    values: {
      name,
      responsible,
      responsibleSecretary,
      initDate,
      deadLine,
      predictedValue,
      priority,
      description,
      status,
      executedValue,
      executedDate,
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    dirty,
    setOpen,
  } = props;
 
  return ( 
    <form onSubmit={handleSubmit} noValidate> 
       <DefaultModalContent dividers>
       <Grid container className={classes.root} spacing={3}>  
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="etapa"
            name="name"
            inputProps={{ autoComplete: 'off' }} 
            onChange={handleChange}
            value={name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

   
          <Grid item xs={6} >
          <CustomDatePicker
           fullWidth
           id="initDate"
           name="initDate"
           label="Data inicial"
           maxDate={deadLine || undefined}
           onChange={(value) =>{
           setFieldValue("initDate",  value || null )}}
           value={initDate}
           error={touched.initDate && Boolean(errors.initDate)}
           helperText={touched.initDate && errors.initDate}
          />
          </Grid>

          <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="predictedValue"
            label="Valor previsto"
            name="predictedValue"        
            InputProps={{
              autoComplete: 'off',
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange}
            value={predictedValue}
            error={touched.predictedValue && Boolean(errors.predictedValue)}
            helperText={touched.predictedValue && errors.predictedValue}
          />
        </Grid>

          <Grid item xs={6}>
          <CustomDatePicker
            fullWidth
            id="deadLine"
            name="deadLine"
            label="Data limite"
        
            minDate={initDate || undefined}
            onChange={(value) =>{
            setFieldValue("deadLine",  value || null)}}
            value={deadLine}
            error={touched.deadLine && Boolean(errors.deadLine)}
            helperText={touched.deadLine && errors.deadLine}
           />
          </Grid>
    
        <Grid item xs={12} style={{marginTop: "0px", marginBottom: "0.5rem"}}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {
              setFieldValue("description", convertToHTML(event.getCurrentContent()));
            }}
            value={description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) =>
             { setFieldValue("responsibleSecretary", value || null)
               setFieldValue("responsible", null)}
            }
            value={responsibleSecretary}
            config={GET_SECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
            error={touched.responsibleSecretary && Boolean(errors.responsibleSecretary)}
            helperText={touched.responsibleSecretary && errors.responsibleSecretary}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            disabled={responsibleSecretary === null}
            onChange={(event, value) =>{
              (value?.id !== null || value === null ? setFieldValue("responsible", value || null):
              setFieldValue("responsible", null))
            }}
            value={responsible}
            nullMessage={"Nenhum usuário nessa secretaria!"}
            config={GET_RESPONSIBLESECRETARY(responsibleSecretary?.id)}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Responsável",
            }}
            error={touched.responsible && Boolean(errors.responsible)}
            helperText={touched.responsible && errors.responsible}
          />
        </Grid>

        <Grid item xs={12} style={{ maxWidth: "40%" }}>
        <SelectApi
            id="priority"
            name="priority"
            label="Prioridade"
            onChange={(e, value) => {
              setFieldValue("priority", value || null);
            }}
            value={priority}
            config={GET_PRIORITY_TASK()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Prioridade",
            }}
            error={touched.priority && Boolean(errors.priority)}
            helperText={touched.priority && errors.priority}
          />
        </Grid>
        </Grid>
        </DefaultModalContent>
    <DefaultModalActions style={{marginRight:"0.5rem"}}>
     <ButtonSuccess
      variant="contained"
      color="primary"
      type="submit"
      success={false}
      loading={isSubmitting}
      disabled={!dirty || isSubmitting}
      > Confirmar
     </ButtonSuccess>
    </DefaultModalActions>
    </form> 
  );
};