import React from "react";
import {Typography, Button, DialogContentText} from "@material-ui/core";

import { useSnackbar } from "notistack";
import api from "../../../services/Api";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions } from "../../../Components/DefaultModal";
 

const POST_DELETE = (body) =>{
  return {
    url:  "axis/delete.php",
    method: 'post',
    data: body,
  };
}

export default function ConfirmDelete({id, open, setOpen, handleClose, updateData}) {
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = React.useState(false)

  const confirm = async ()=>{
     setLoad(true)
     await api(POST_DELETE({id: id})).then(r=>{
      const {data} = r;
      switch (data.status) {
        case 'success':{
          updateData()
          handleClose(false)
          enqueueSnackbar("Programa apagada !!!", { variant: "success" }) 
          break;
        }
        case 'caution':{
          setLoad(false)
          handleClose(false)
          enqueueSnackbar(data.msg, { variant: "warning" }); 
          break;
        } 
        case 'error':{
          setLoad(false)
          handleClose(false)
          enqueueSnackbar(`Error ao apagar programa !!!`, { variant: "Error" });
          break;
        } 
        default: {
          setLoad(false)
          handleClose(false)
          enqueueSnackbar(`Error ao apagar programa !!!`, { variant: "Error" });
          break;
        } 
      }
     }
   ).catch(e=>{
     setLoad(false)
     enqueueSnackbar(`Error ao apagar programa !!!`, { variant: "error" });
   })
  }
  
  return (
    <DefaultModal open={open} onClose={setOpen} title={"Apagar programa"}>
      <DialogContentText style={{padding:"1rem"}}>
       <Typography component="h1">Deseja apagar permanentemente o programa? </Typography>
      </DialogContentText>  
  
      <DefaultModalActions style={{marginTop:"1rem"}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        > Não
        </Button>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          autoFocus
          success={false}
          loading={load}
          disabled={load} 
          onClick={confirm}
        > Sim
        </ButtonSuccess>
      </DefaultModalActions>
    </DefaultModal>
  );
}
