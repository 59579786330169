import React from "react";
import {
  Grid,
} from "@material-ui/core";
 
import Form from "./Form";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
 
const initialProjectValues = {
  name: "", 
  responsible: null,
  responsibleSecretary: null,
  status: null,
  situation: null,
  priority: null,
  initDate : null,
  deadLine : null,
 
  // detail: {
  //   status: false,
  //   finances: false,
  //   physicalProgress: false,
  //   progress: false,
  //   activity: false,
  // },
};

const openFileInNewTab = async (response/*: AxiosResponse*/, filename/*: string*/ = 'download') => {
  const {data} = response;
  if (!(data instanceof Blob)) return;
  const blob = new Blob([data], { type: 'application/pdf' });
  // IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data, filename);
    return;
  }
  // Chrome, FF
  const fileUrl = URL.createObjectURL(blob);
  const w = window.open(fileUrl, '_blank', );
  w && w.focus();
};

const POST_REPORT = (body) => {
  return {
    url: `pdf/tasks.php`,
    method: 'post',
    responseType: 'blob', // important
    data: body,
  };
};

const ReportsPanel = () => {
  const {id} = useParams()
  const { enqueueSnackbar } = useSnackbar();
  const submit = async (values, { setSubmitting }) => {
      const buffer ={
          projectId           : id,
          name                : values.name,
          initDate            : values.initDate,
          deadLine            : values.deadLine,
          priority            : values.priority             === null?0:values.priority.id,
          status              : values.status               === null?0:values.status.id,
          situation           : values.situation            === null?0:values.situation.id,
          responsible         : values.responsible          === null?0:values.responsible.id,
          responsibleSecretary: values.responsibleSecretary === null?0:values.responsibleSecretary.id,
      //  detail              : values.detail,
       }

      await api(POST_REPORT(buffer)).then((response) => {
        openFileInNewTab(response, "teste.pdf")
        setSubmitting(false);  
      }).catch(e=>{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao visualizar relatório !!!`, { variant: "warning" });
      });
  };


  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
         initialValues={initialProjectValues}
         onSubmit={submit}
         >
          {(props) => <Form {...props} />}
        </Formik>
      </Grid>
    </Grid>
  );
};

 

/*
nome {talvez}
rotina
grupo
eixo
data inicial/limite/concluido
valor previsto/executado
secretaria responsavel
responsavel
status (Concluído, Em andamento, Em atraso, Não iniciado, Cancelado)
*/

export default ReportsPanel;