import React from "react";
import { createStyles, makeStyles, Grid, Typography, Paper } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";
import { Divider } from "@material-ui/core";
import ViewList from "../ViewList";
// Gráfico em barra com a quantidade de projetos por secretaria 

export const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: 5,
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 300},
    [theme.breakpoints.up("md")]: {width: "100%", height: 350},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 350},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 400},
    paddingTop:"1rem",
  }
})
);
 
const GET_INFO = () => {
  return {
    method: "get",
    url: `dashboard/secretaryProgress.php`,
  };
};

const getPercentWidth = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}

function ViewChart({data, setViewId}){
  const {cancelado, concluido, em_andamento, em_atraso, nao_iniciados, secretarias, Ids} = data

  function calcSizeBar(){
    return getPercentWidth(1140, secretarias.length, 40)
  }

 const series = [
  {name: 'Nao iniciados' , data: nao_iniciados},
  {name: 'Em andamento'  , data: em_andamento},
  {name: 'Em atraso'     , data: em_atraso},
  {name: 'Cancelado'     , data: cancelado,}, 
  {name: 'Concluído'     , data: concluido},
]

  const options ={
    colors: ["#808080", "#2E93fA","#FFA500", "#ff3330", "#66DA26", ], 
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
         setViewId({
          name: "",
          group: null,
          axis: null,
          responsible: null,
          responsibleSecretary: Ids[config.dataPointIndex], //null
          status: config.seriesIndex,
          type: {
            routine: false,
            governmentPlan: false,
          },
        })
        }
      },
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true
      },
    },
    plotOptions: {
      bar: {
          columnWidth: calcSizeBar(), 
          borderRadius: 5,
          horizontal: false,
          rangeBarOverlap: false,
          rangeBarGroupRows: false,
          startingShape: "flat",
          endingShape: "rounded",
      },
    },
    xaxis: {
      type: 'category',
      categories: secretarias,
      labels:{
        show: true,
        rotate: -25,
        trim: true,
        minHeight: 0,
        maxHeight: 60,
        style:{
         fontSize: "10px"
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      floating: true,
      offsetY: 0,  
    },
    fill: {
      opacity: 1
    }
  }
  return <ReactApexChart options={options} series={series} type="bar" width="100%" height="100%"/>
}

const init ={
  cancelado: [],
  concluido: [],
  em_andamento: [],
  em_atraso : [],
  nao_iniciados: [],
  secretarias:[],
  Ids: [],
}

export default function AmountSecretary() {
  const classes = useStyles();
  const [data, setData] = React.useState(init)
  const [viewId, setViewId] = React.useState(null)

  const updateData = async () => {
     await api(GET_INFO()).then(({data}) => {
       if (data === null) return

       const cancelado      = data.map(r=> Number(r.dados.cancelado))
       const concluido      = data.map(r=> Number(r.dados.concluido))
       const em_andamento   = data.map(r=> Number(r.dados.em_andamento))
       const em_atraso      = data.map(r=> Number(r.dados.em_atraso))
       const nao_iniciados  = data.map(r=> Number(r.dados.nao_iniciados))

       const Ids = data.map(r=> Number(r.id)) 
       const secretarias = data.map(r=>
         `${r.name} - Total: ${Number(r.dados.cancelado)+
                               Number(r.dados.concluido)+
                               Number(r.dados.em_andamento)+
                               Number(r.dados.em_atraso)+
                               Number(r.dados.nao_iniciados)}`)

      setData({cancelado, concluido, em_andamento , em_atraso, nao_iniciados, secretarias, Ids})
    }).catch(e=>{
      setData(init)
    });
  };

  const handleClose =()=>{
     setViewId(null)
  }

  React.useEffect(() => {
    updateData();
  }, []);

 
 return (
 <>
 <ViewList search={viewId} handleClose={handleClose} title="Projetos por secretaria"/>
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> Projetos por secretaria </Typography>
   <Divider/>
  </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart data={data} setViewId={setViewId}/>
   </Grid>
 </Grid>
 </>  
 );
}
