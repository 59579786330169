import React from "react";
import CustomTable from "../../../../Components/CustomTable";

export default function ReadTable({data, error, load }) {
  const columns = [
    { name: "id", options: { display: "false" } },
    { name: "actionDate", label: "Acessos" },
    //{ name: "actionDate", label: "Data" },
  ];

  const options ={
    search: true,
    print: true,
    elevation: 1,
    pagination: data?.length > 10,
  }
 
  return (
    <>
    <CustomTable data={data} columns={columns} opt={options} error={error} load={load}/>
    </>);
}

