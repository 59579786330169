import React from "react";
import {
  Typography,
  Button,
  DialogContentText,
} from "@material-ui/core";

import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";

export const POST_DELETE_COMMENT = (body) =>{
  return {
    url: "project/observationdelete.php",
    method: 'post',
    data: body,
  };
}

export default function ConfirmDelete({id, handleUpdate, open, setOpen}) {
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = React.useState(false)

  const confirm = async ()=>{

  setLoad(true)
  const buffer ={
    id: id
  }
 
  await api(POST_DELETE_COMMENT(buffer)).then(r=>{
      const {data} = r;
      if(data.status === "success"){
        handleUpdate()
        setLoad(false)
        setOpen()
        enqueueSnackbar("Comentário apagado !!!", { variant: "success" });
      }else{
        setLoad(false)
        enqueueSnackbar(`Error ao apagar comentário!!!`, { variant: "error" });
      }
     }
    ).catch(e=>{
      setLoad(false)
      enqueueSnackbar(`Error ao apagar comentário !!!`, { variant: "error" });
    })
   }

  return (
    <DefaultModal open={open} handleClose={() => setOpen(false)} title={"Apagar comentário"}>
      <DefaultModalContent dividers>
      <DialogContentText  style={{padding:"0.5rem"}}>
        <Typography component="h1">
          Deseja apagar permanentemente o comentário?
        </Typography>
      </DialogContentText>
      </DefaultModalContent>

      <DefaultModalActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        > Não
        </Button>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={load}
          disabled={load} 
          onClick={confirm}
        > Sim
        </ButtonSuccess>
      </DefaultModalActions>
    </DefaultModal>
  );
}
