import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { UserStorage } from "./Context/UserContext";
import Projects from "./Pages/Projects";
import Login from "./Pages/Login";
import Administrator from "./Pages/Administrator";
import View from "./Pages/View";
import GlobalStyles from "./theme/GlobalStyles";
import UserPanel from "./Pages/UserPanel";
import Teste from "./Pages/Teste";
import LayoutMaster from "./Components/Layout/LayoutMaster";
import ProtectedRoute from "./Components/Helper/ProtectedRoute";
 
function App() {
  return (
    <BrowserRouter>
      <UserStorage>
        <GlobalStyles/>
        <ThemeProvider theme={theme.default}>
          <LayoutMaster>
            <Routes>
             <ProtectedRoute exact path="/projects/*"          element={<Projects />}/>
             <ProtectedRoute exact path="/projects/view/:id/*" element={<View />}/>
             <ProtectedRoute exact path="/administrator/*"     element={<Administrator />}/>
             <ProtectedRoute exact path="/userpanel/*"         element={<UserPanel />}/>

             <Route          exact path="/auth/*"              element={<Login />} />
             <Route          exact path="/*"                   element={<Navigate to="/auth" />} />
             <Route          exact path="/teste"               element={<Teste />} />
            </Routes>
          </LayoutMaster>
        </ThemeProvider>
      </UserStorage>
    </BrowserRouter>
  );
}

export default App;
