import React from "react";
import { Button, Grid, createStyles, makeStyles, Container} from "@material-ui/core";

import Table from "./Table";
import NewSecretary from "./New";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";

import useAxios from "../../../Context/Hooks/useAxios";
import { UserContext } from "../../../Context/UserContext";
import { GET_SECRETARY_TABLE } from "../../../services/options";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    main: {
      display: "grid",
    },
    info: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
    },
    table:{
      [theme.breakpoints.up("xs")]: { minHeight: 450, },
      [theme.breakpoints.up("sm")]: { minHeight: 450, },
      [theme.breakpoints.up("md")]: { minHeight: 450, },
      [theme.breakpoints.up("lg")]: { minHeight: 450, },
      [theme.breakpoints.up("xl")]: { minHeight: 650, },
    },
  })
);

export default function MainSecretary() {
  const classes = useStyles();
  const { privilege } = React.useContext(UserContext);
  const {buffer, loading, error, request} = useAxios();
  const [open, setOpen] = React.useState(false);

  const updateData = async () =>{
    await request(GET_SECRETARY_TABLE()) 
  }

  React.useEffect(()=>{
    updateData()
  },[])
 
 return (
 <div className={classes.root}>
  <NewSecretary open={open} setOpen={setOpen} updateData={updateData} />
  <Container className={classes.main} maxWidth="xl">
   <Grid item xs={12} className={classes.info}>
    <CurrentPageInfo primary="Administrador" secondary="Secretarias"/>
    <Button
     variant="contained"
     color="primary"
     style={{marginBottom: "1rem"}}
     onClick={() => setOpen(true)}
     disabled={!privilege.administrator}>
      Nova Secretaria
    </Button>
   </Grid>    
   <Grid item xs={12} className={classes.table}>
    <Table data={buffer} updateData={updateData} error={error} load={loading} />
   </Grid>
  </Container>
 </div>
 );
}