import React from "react";
import CustomTable from "../../../../Components/CustomTable";
import { UserContext } from "../../../../Context/UserContext";
import { ViewModal } from "./ViewModal";
 

export default function Table({id, data, updateData, error, load}) {
 
  const { privilege  } = React.useContext(UserContext);
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(true);

  const handleOpen = (id)=>{
    setRowId(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setRowId(null)
    setOpen(false)
  }

  const options ={
    onRowClick: (rowData, rowState) => handleOpen(rowData[0])
  }

 
const columns = [
  { name: "gutId", options: { display: "false" } },
  { name: "risk", label: "Risco", options: { filter: true, sort: true}, },
  { name: "gravity", label: "Gravidade", options: { filter: true, sort: true}, },
  { name: "urgency", label: "Urgência", options: { filter: true, sort: true}, }, 
  { name: "trend", label: "Tendência", options: { filter: true, sort: true}, },
  { name: "average",  label: "Nível do problema", options: { filter: true, sort: true}, },
  { name: "gut", label: "GUT", options: { filter: true, sort: true}, },
  { name: "executedValue", label: "Valor executado", options: { filter: true, sort: true},},
];
 
return (
  <>
  {rowId &&  (privilege.administrator || privilege.collaboratorNv1) &&
   <ViewModal id={rowId} open={open} close={handleClose} handleUpdate={updateData}/>}
   <CustomTable data={data} columns={columns} opt={options} error={error} load={load}/>
  </>);
}
