import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { SnackbarProvider } from "notistack";
import Collapse from '@material-ui/core/Collapse';

import {
  Button,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  Tooltip,
  Paper,
  Icon,
  makeStyles
} from "@material-ui/core";

import {
  Settings,
  WebAsset,
} from "@material-ui/icons";

import MenuIcon from "@material-ui/icons/Menu";
import { UserContext } from "../../../Context/UserContext";
import ShowMenu from "./ShowMenu";
import { MenuNotification } from "../../Screens/ViewNotification";
import { MenuUser } from "./MenuUser";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    
  },
  drawer: {
    width: 70,
    flexShrink: 0,
    whiteSpace: "nowrap",
    minHeight: 'auto',
  },
  toolbar: {
    padding: theme.spacing(0, 2, 0, 2),
    height: 56
   // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    width: `100vw`,
    minHeight: "100vh",
    padding: theme.spacing(4, 3, 2, 3),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(2, 2, 1, 2),
    },
  },
  grow: {
    flexGrow: 1,
  },
  selectedPage: {
    // background: `linear-gradient(30deg, #CCCCCC  50%, #FFFFFFFF 100%)`,
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "5px",
    borderLeft: "5px solid #1D91DA",
    // borderRadius: 10,
  },
  page: {
    borderLeft: "5px solid #0000",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export const LayoutContext = React.createContext();

export default function LayoutMaster({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showLayout, setShowLayout] = React.useState(false);
  const [listMenu, setListMenu] = React.useState(undefined);
  const [notification, setNotification] = React.useState(1);

  const location = useLocation();
  const actualLocation = location.pathname;
  const isSelected = (route) => actualLocation === route;

  const handleDrawerOpen = ()  => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <LayoutContext.Provider
      value={{
        open,
        setOpen,
        showLayout,
        setShowLayout,
        listMenu,
        setListMenu,
        notification,
        setNotification, }} >
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} TransitionComponent={Collapse} maxSnack={3}>
      {showLayout ? (
        <div className={classes.root}>
          <AppBar
            color="inherit"
            position="fixed"
          >
            <Toolbar className={classes.toolbar} variant="dense" >
              {listMenu && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => handleDrawerOpen()}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              )}
              <ToolBarContente />
            </Toolbar>
          </AppBar>
        
          {listMenu && (  
           <>    
            <ShowMenu listMenu={listMenu} open={open} setOpen={handleDrawerClose}/>
            <Paper className={classes.drawer}>
              <div className={classes.toolbar}/>
              <Divider />
              <List>
                {listMenu.map((listMenu, index) => (
                  <Link key={index} to={listMenu.to} passhref="true">
                    <ListItem
                      style={{  paddingBottom:"1rem", paddingTop:'1rem'}}
                      button
                      component="a"
                      className={
                        isSelected(listMenu.to)
                          ? classes.selectedPage
                          : classes.page
                      }
                    >
                     <Tooltip title={listMenu.name} aria-label={listMenu.name}>
                      <ListItemIcon >
                        <Icon color={isSelected(listMenu.to) ?"primary":"inherit"} >
                        {listMenu.icon}
                        </Icon>
                      </ListItemIcon>
                     </Tooltip>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Paper>
            </>
          )}
           <main className={classes.content}>
           <div className={classes.toolbar} />
            {children}
           </main>
        </div>
      ) : (
        children
      )}
      </SnackbarProvider>  
    </LayoutContext.Provider>
  );
}

const ToolBarContente = () => {
  const classes = useStyles();
  const { userInfo } = React.useContext(UserContext);
  const { numberLate } = userInfo;
  const { notification, setNotification } = React.useContext(LayoutContext);
  const { privilege } = React.useContext(UserContext);
  const navigate = useNavigate();
  
  React.useEffect(()=>{
    setNotification(numberLate)
  },[numberLate])

  return (
    <>
      <Button
          variant="contained"
          color="default"
          onClick={() => navigate("/projects")}
          style={{padding:'15px', height: 36,  marginLeft: 25, borderRadius: 15}}
        >
          <WebAsset className={classes.extendedIcon} />
           Projetos 
        </Button>

      <div className={classes.grow}/>
      <Tooltip placement="top" title="Administrador" aria-label="Administrador">
          <span>
            <IconButton
              aria-label="Administrador"
              color="inherit"
              disabled={!privilege.administrator && !privilege.collaboratorNv1}
              onClick={() => navigate("/administrator/user")}
            >
              <Settings />
            </IconButton>
          </span>
      </Tooltip>
      <MenuNotification notification={notification}/>
      <MenuUser/>
    </>
  );
};
