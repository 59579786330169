import React from "react";
import CustomTable from "../../../../Components/CustomTable";
import Edit5W2H from "./Edit"
import { FormatDate, FormatRealNumber } from "../../../../Components/Helper/FormatValues";

export default function Table({data, loading, error, updateData}) { 
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(true);

  const handleOpen = (id)=>{
    setRowId(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setRowId(null)
    setOpen(false)
  }

  const options ={
    search: true,
    print: true,
    viewColumns: true,
    download: false,
    onRowClick: (rowData, rowState) => handleOpen(rowData[0])
  }
 
  const columns = [
    { name: "id", options: { display: "false" } },
    { name: "risk",  label: "Risco",            options: { filter: true, sort: true } },
    { name: "what",  label: "O que fazer?",     options: { filter: true, sort: true },},
    { name: "why",   label: "Por que fazer?",   options: { filter: true, sort: true } },
    { name: "whereField", label: "Onde será feito?", options: { filter: true, sort: true } },
    { name: "who",   label: "Quem irá fazer?",  options: { filter: true, sort: true },},
    { name: "initDate",  label: "Início",       options: { filter: true, sort: true, customBodyRender: (value) => FormatDate(value, "Não definida"),} },
    { name: "deadLine",  label: "Previsão de término", options: { filter: true, sort: true, customBodyRender: (value) => FormatDate(value, "Não definida"),} },
    { name: "predictedValue", label: "Valor previsto",
      options: { filter: false, sort: true , customBodyRender: (value) => FormatRealNumber(value),} },
    { name: "situation", label: "Situação", options: { filter: true, sort: true, } },
  ];

  return( 
   <> 
    {rowId && <Edit5W2H id={rowId} open={open} close={handleClose} handleUpdate={updateData}/>}  
    <CustomTable data={data} columns={columns} opt={options} error={error} load={loading}/>
   </>
  );
}