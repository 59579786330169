import React from "react";
import { UserContext } from "../../../../Context/UserContext";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { Grid, createStyles, makeStyles, TextField } from "@material-ui/core";

import { SelectApi } from "../../../../Components/SelectsApi";
import {
  GET_RESPONSIBLESECRETARY,
  POST_UPDATE_5W2H,
  GET_LIST_RISK,
  POST_REQUEST_5W2H,
  GET_STATUS_TASK,
} from "../../../../services/options";
import { NumberFormatCustom } from "../../../../Components/Helper/FormatValues";
import { useSnackbar } from "notistack";
import api from "../../../../services/Api";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import DefaultModal, {
  DefaultModalActions,
  DefaultModalContent,
} from "../../../../Components/DefaultModal";
import { CustomDatePicker } from "../../../../Components/CustomDatePicker";
import { SelectCustom } from "../../../../Components/SelectsCustom";
import { ViewProjectContext } from "../..";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: {
        width: 380,
        padding: theme.spacing(0, 1, 0, 1),
      },
      [theme.breakpoints.up("sm")]: {
        width: 450,
        padding: theme.spacing(0, 1, 0, 1),
      },
      [theme.breakpoints.up("md")]: {
        width: 450,
        padding: theme.spacing(0, 1, 0, 1),
      },
      [theme.breakpoints.up("lg")]: {
        width: 450,
        padding: theme.spacing(0, 1, 0, 1),
      },
      [theme.breakpoints.up("xl")]: {
        width: 500,
        padding: theme.spacing(0, 1, 0, 1),
      },
    },
  })
);

const validationSchema = Yup.object({
  risk: Yup.object().shape().required("Obrigatório").nullable(),
  what: Yup.string().required("Obrigatório"),
  why: Yup.string().required("Obrigatório"),
  whereField: Yup.string().required("Obrigatório"),
  who: Yup.string().required("Obrigatório"),
  //when     : Yup.date().required("Obrigatório").nullable().typeError("Data inválida"),
  initDate: Yup.date()
    .required("Obrigatório")
    .nullable()
    .typeError("Data inválida"),
  deadLine: Yup.date()
    .required("Obrigatório")
    .nullable()
    .typeError("Data inválida"),
  predictedValue: Yup.number().required("Obrigatório").nullable(),
  how: Yup.string().required("Obrigatório"),
  situation: Yup.object().shape().required("Obrigatório").nullable(),
});

const initial = {
  id: 0,
  risk: null,
  whereField: "",
  what: "",
  why: "",
  who: "",
  initDate: null,
  deadLine: null,
  executedDate: null,
  predictedValue: null,
  executedValue: null,
  how: "",
  status: null,
  canceled: 0,
  stoped: 0,
  justification: "",
};

export default function Edit5W2H({ id, open, close, handleUpdate }) {
  const [data, setData] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const rqstInfo = async () => {
    await api(POST_REQUEST_5W2H({ id: id })).then((r) => {
      const data = r?.data[0];
      console.log(data);
      setData(data);
    });
  };

  React.useEffect(() => {
    if (open) rqstInfo();
  }, [open]);

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      id: id,
      riskId: values.risk.id,
      what: values.what,
      why: values.why,
      whereField: values.whereField,
      who: values.who,
      initDate: values.initDate,
      deadLine: values.deadLine,
      predictedValue: !values.predictedValue ? 0 : values.predictedValue,
      how: values.how,
      situationId: values.situation.id,
    };

    await api(POST_UPDATE_5W2H(buffer))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          handleUpdate();
          setSubmitting(false);
          handleClose();
          enqueueSnackbar("Plano de ação atualizado !!!", {
            variant: "success",
          });
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Erro ao editar plano de ação`, { variant: "Error" });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ao editar plano de ação ${e}`, {
          variant: "Error",
        });
      });
  };

  const handleClose = () => {
    close(false);
  };

  return (
    <DefaultModal
      open={open}
      handleClose={handleClose}
      loading={data === null}
      title="Editar plano de ação"
    >
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const { privilege } = React.useContext(UserContext);
  const { infosData } = React.useContext(ViewProjectContext);
  const { isCanceled } = infosData;
  const classes = useStyles();
  const { id } = useParams();
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = props;

  const GET_SITUATION = () => {
    return {
      method: "get",
      url: `loadSituation.php`,
    };
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <SelectApi
              fullWidth
              id="risk"
              name="risk"
              onChange={(e, value) => setFieldValue("risk", value || null)}
              value={values.risk}
              config={GET_LIST_RISK(id)}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                variant: "outlined",
                label: "Risco",
              }}
              error={touched.risk && Boolean(errors.risk)}
              helperText={touched.risk && errors.risk}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              variant="outlined"
              size="small"
              id="what"
              label="o que fazer?"
              name="what"
              inputProps={{ autoComplete: "off" }}
              onChange={handleChange}
              value={values.what}
              error={touched.what && Boolean(errors.what)}
              helperText={touched.what && errors.what}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              variant="outlined"
              size="small"
              id="why"
              label="Por que fazer?"
              name="why"
              inputProps={{ autoComplete: "off" }}
              onChange={handleChange}
              value={values.why}
              error={touched.why && Boolean(errors.why)}
              helperText={touched.why && errors.why}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              variant="outlined"
              size="small"
              id="whereField"
              label="Onde será feito?"
              name="whereField"
              inputProps={{ autoComplete: "off" }}
              onChange={handleChange}
              value={values.whereField}
              error={touched.whereField && Boolean(errors.whereField)}
              helperText={touched.whereField && errors.whereField}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              variant="outlined"
              size="small"
              id="who"
              name="who"
              label="o que fazer?"
              inputProps={{ autoComplete: "off" }}
              onChange={handleChange}
              value={values.who}
              error={touched.who && Boolean(errors.who)}
              helperText={touched.who && errors.who}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              variant="outlined"
              size="small"
              id="how"
              label="Como será feito?"
              name="how"
              inputProps={{ autoComplete: "off" }}
              onChange={handleChange}
              value={values.how}
              error={touched.how && Boolean(errors.how)}
              helperText={touched.how && errors.how}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectApi
              fullWidth
              id="situation"
              name="situation"
              onChange={(e, value) => {
                setFieldValue("situation", value || null);
              }}
              value={values.situation}
              config={GET_SITUATION()}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                variant: "outlined",
                label: "Situação",
              }}
              error={touched.situation && Boolean(errors.situation)}
              helperText={touched.situation && errors.situation}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomDatePicker
              fullWidth
              id="initDate"
              name="initDate"
              label="Início"
              maxDate={values.deadLine || undefined}
              onChange={(value) => {
                setFieldValue("initDate", value || null);
              }}
              value={values.initDate}
              error={touched.initDate && Boolean(errors.initDate)}
              helperText={touched.initDate && errors.initDate}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="predictedValue"
              name="predictedValue"
              label="Valor previsto"
              inputProps={{ autoComplete: "off" }}
              InputProps={{
                autoComplete: "off",
                inputComponent: NumberFormatCustom,
              }}
              onChange={handleChange}
              value={values.predictedValue}
              error={touched.predictedValue && Boolean(errors.predictedValue)}
              helperText={touched.predictedValue && errors.predictedValue}
            />
          </Grid>

          <Grid item xs={6}>
            <CustomDatePicker
              fullWidth
              id="deadLine"
              name="deadLine"
              label="Previsão de término"
              minDate={values.initDate || undefined}
              onChange={(value) => {
                setFieldValue("deadLine", value || null);
              }}
              value={values.deadLine}
              error={touched.deadLine && Boolean(errors.deadLine)}
              helperText={touched.deadLine && errors.deadLine}
            />
          </Grid>
        </Grid>
      </DefaultModalContent>
      <DefaultModalActions style={{ marginRight: "0.5rem" }}>
        <ButtonSuccess
          style={{ width: 110 }}
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          disabled={!dirty || privilege.cityHall || isCanceled}
          loading={isSubmitting}
        >
          {" "}
          Confirmar
        </ButtonSuccess>
      </DefaultModalActions>
    </form>
  );
};
