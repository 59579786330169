import React from "react";

import {
  Grid,
  createStyles,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from "@material-ui/core";

import { SpeedDial, SpeedDialAction } from '@material-ui/lab';

import * as Icons from "@material-ui/icons";
import { UserContext } from "../../../Context/UserContext";
import NewPassword from "./NewPassword";
import EditUser from "./EditUser";
import { CardProjects } from "./CardProjects";
import { CardTask } from "./CardTask";

export const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      display: "flex",
      alignContent: "start",
      alignItems: "start",
      padding: theme.spacing(1 , 2, 1 ,2),  
      backgroundColor: '#FFFFFF', 
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "10px",
      boxShadow: "0px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
      [theme.breakpoints.up("xs")]: { width: 650, minHeight: 650  },  
      [theme.breakpoints.up("sm")]: { width: 650, minHeight: 650  },  
      [theme.breakpoints.up("md")]: { width: 650, minHeight: 650  },  
      [theme.breakpoints.up("lg")]: { width: 750, minHeight: 650  },  
      [theme.breakpoints.up("xl")]: { width: 850, minHeight: 790  },  
    },
    speedDial:{
      marginLeft:"0.3rem",
      transform: 'scale(1)',
    }
  })
);

export default function UserProfile() {
  const classes = useStyles();
  const [select, setSelect] = React.useState(null)
  const [openView, setOpenView] = React.useState(false)
  const [openDial, setOpenDial] = React.useState(false);
  const { userInfo } = React.useContext(UserContext);

  const userData = [
    {
      icon: <Icons.PermIdentity/>,
      text: "Usuário: ",
      data: userInfo.login,
    },
    {
      icon: <Icons.AccountCircle/>,
      text: "Nome: ",
      data: userInfo.name,
    },
    {
      icon: <Icons.Email/>,
      text: "Email: ",
      data: userInfo.email,
    },
    {
      icon: <Icons.Phone />,
      text: "Telefone: ",
      data: userInfo.phone,
    },
    {
      icon: <Icons.Smartphone />,
      text: "Celular: ",
      data: userInfo.cellPhone,
    },
    {
      icon: <Icons.AccountBalance />,
      text: "Secretaria: ",
      data: userInfo.secretary,
    },
    {
      icon: <Icons.HowToReg/>,
      text: "Nível de acesso: ",
      data: userInfo.accessLevel,
    },
    {
      icon: <Icons.CheckCircle />,
      text: "Status: ",
      data: userInfo.status === "1" ? "Ativo" : "Inativo",
    },
  ]

  const actions = [
    { icon: <Icons.Edit />, 
      name: 'Atualizar informações',
      option: 0, 
      disabled: false},  

    { icon: <Icons.VpnKey/>, 
      name: 'Alterar senha',
      option: 1, 
      disabled: false},    
    ]

    const handleOpenView =(id)=>{
      setSelect(id)
      setOpenView(true)
    }
  
    const handleCloseView =()=>{
      setOpenView(false)
      setSelect(null)
    }

    const handlActionsOpen = (e) => {
      if(e.type === "mouseenter" || e.type === "click")
      setOpenDial(true);
    };
  
    const handlActionsClose = (e) => {//mouseleave
      if(e.type === "mouseleave")
      setOpenDial(false);
    };
 
    const getSelectContent = React.useCallback((select) => { 
      switch (select) {
        case 0: return <EditUser open={openView} setOpen={handleCloseView}/>
        case 1: return <NewPassword open={openView} setOpen={handleCloseView}/>
        default: return null;
      }
    }, [select]);
 
 return (
 <>
 {getSelectContent(select)}
  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.main}>
   <Grid item xs={12}>
    <SpeedDial
        ariaLabel="SpeedDialClick"
        className={classes.speedDial}
        icon={ <Icons.Settings  />  }
        direction="right"
        FabProps={{size:"small" }}
        onClose={handlActionsClose}
        onOpen={handlActionsOpen}
        open={openDial} 
        >
        {actions.filter(r=> !r.hide).map((action) => (
         <SpeedDialAction
          tooltipPlacement="top"
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          disabled={action.disabled}
          hidden={action.disabled}
          onClick={()=>handleOpenView(action.option)}/>
        ))}
    </SpeedDial>  
   </Grid>
 
   <Grid item xs={12}>
    <List dense style={{width: "100%"}}>
    { userData.filter(r=> r.userData !== null).map((value) => (
     <ListItem alignItems="start" key={value.text}>
      <ListItemIcon >{value.icon}</ListItemIcon>
     <ListItemText style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
      primary={<Typography variant="h6" component="span">{value.text}</Typography>}
      secondary={<Typography component="span" style={{marginLeft: "0.5rem" }} color="textSecondary">  {value.data} </Typography>}/>
     </ListItem>))}
    </List>
   </Grid>

   {/* <Grid item xs={12} style={{margin: "1rem 0rem"}}>
    <Divider/>
   </Grid>


  <Grid container xs={12}> 
   <Grid item xs={6} style={{paddingRight: "1rem"}}>
     <CardProjects value={[10,20,30,40]}/>
   </Grid>

   <Grid item xs={6} style={{paddingLeft: "1rem"}}>
     <CardTask value={[10,20,30,40]}/>
   </Grid>
  </Grid> */}

  </Grid>
 </> 
  );
}
