import React from "react";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
  Box,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
 
import { TextEditor } from "../../../Components/Editor";
import ConfirmDelete from "./ConfirmDelete"
import { useSnackbar } from "notistack";
import { GET_GROUP_DESCRIPTION, POST_UPDATE_GROUP } from "../../../services/options";
import api from "../../../services/Api";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";
import { CustomDatePicker } from "../../../Components/CustomDatePicker";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 520, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
  initDate: Yup.date().required("Obrigatório").nullable().typeError("Data inválida"),
  deadLine: Yup.date().min(
    Yup.ref('initDate'),
    "A data final não pode ser anterior à data de inícial"
  ).required("Obrigatório").nullable().typeError("Data inválida"),
 // description: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
});

export default function EditGroup({id, open, setOpen, updateData}) {
  const [info, setInfo] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar();

  const getData = async ()=>{
    await api(GET_GROUP_DESCRIPTION(id)).then(r=>{
       const data = r.data[0]
       setInfo({  
       name: data.name,
       initDate: data.initDate === '0000-00-00'?null:data.initDate,
       deadLine: data.deadLine === '0000-00-00'?null:data.deadLine,
       description: data.description,
       status: Number(data.status),})
     }).catch(e=>{
      enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
     })   
  }
 
  const submit = async (values, { setSubmitting }) => {
    const buffer ={
      id: id, 
      name: values.name,
      initDate: values.initDate,
      deadLine: values.deadLine,
      description: values.description,
      status: values.status}

    await api(POST_UPDATE_GROUP(buffer)).then(r=>{
      const {data} = r;
      if (data.status === "success"){
        updateData()
        setSubmitting(false)
        setOpen()
        enqueueSnackbar("Portifólio atualizado !!!", { variant: "success" });
      }else{
        enqueueSnackbar(`Erro ao atualizar portifólio`, { variant: "Error" });
        setSubmitting(false)
      }
    }).catch(e=>{
      enqueueSnackbar(`Erro ao atualizar portifólio`, { variant: "Error" });
      setSubmitting(false)
    })
 };
 
React.useEffect(()=>{
  if(open) getData()
},[open])

return (
 <>
  <DefaultModal 
   open={open} 
   loading={info === null}
   handleClose={setOpen} 
   title="Editar portifólio">
    <Formik
      initialValues={info}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Forms {...props} />}
    </Formik>  
  </DefaultModal>
 </>   
);
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
    <DefaultModalContent dividers>
      <Grid container  className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: 'off' }} 
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

       
          <Grid item xs={6} >
          <CustomDatePicker
           fullWidth
           id="initDate"
           name="initDate"
           label="Data inicial"
       
           maxDate={values.deadLine || undefined}
           onChange={(value) =>{
           setFieldValue("initDate",  value || null )}}
           value={values.initDate}
           error={touched.initDate && Boolean(errors.initDate)}
           helperText={touched.initDate && errors.initDate}
          />
          </Grid>

          <Grid item xs={6}>
          <CustomDatePicker
            fullWidth
            id="deadLine"
            name="deadLine"
            label="Data limite"
     
            minDate={values.initDate || undefined}
            onChange={(value) =>{
            setFieldValue("deadLine",  value || null)}}
            value={values.deadLine}
            error={touched.deadLine && Boolean(errors.deadLine)}
            helperText={touched.deadLine && errors.deadLine}
           />
          </Grid>
       
    
          <Grid item xs={12}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {
              setFieldValue("description", convertToHTML(event.getCurrentContent()));
            }}
            value={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                id="status"
                value={values.status}
                onChange={(e, value) => {
                  setFieldValue("status", Number(value))}
                }
              >
             <Box style={{display: "flex"}}>  
              <FormControlLabel value={1} control={<Radio />} label="Ativo" />
              <FormControlLabel value={0} control={<Radio />} label="Inativo" />
             </Box>
              </RadioGroup>
            </FormControl>
            </Grid>
           </Grid> 
          </DefaultModalContent>
          <DefaultModalActions style={{marginRight: "0.5rem"}}>
          <ButtonSuccess
             style={{width: 120}}
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             loading={isSubmitting}
            > Salvar
            </ButtonSuccess>
        </DefaultModalActions>
    </form>
  );
};
 