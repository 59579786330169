import axios from "axios";
import { getToken } from "./auth";
 
// const isDev = true;
const BASE_URL = "https://agevap.townsystem.com.br/api/"
//  "https://dev.draftmanagementproject.com/api/":
//  "https://guara.draftmanagementproject.com/api/" 

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const auth = token ? `Bearer ${token}` : '';
    config.headers.common['Authorization'] = auth;
    return config;
  },
  (error) => Promise.reject(error),
);

export default api;
 
 