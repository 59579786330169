import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { MaskCel, MaskPhone } from "../../../Components/Helper/FormatValues";
import api from "../../../services/Api";
import { useSnackbar } from "notistack";
import { UserContext } from "../../../Context/UserContext";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent, } from "../../../Components/DefaultModal";

export const POST_UPDATE_USER = (body) => {
  return {
    url: "user/alterEmailCell.php",
    method: "post",
    data: body,
  };
};

const validationSchema = Yup.object().shape({
  form_email: Yup.string().email("E-mail inválido").required("Obrigatório"),
});

const EditUser = ({ open, setOpen }) => {
  const [info, setInfo] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, updateUserInfo } = React.useContext(UserContext);

  React.useEffect(() => {
    if (userInfo)
      setInfo({
        name: userInfo.name,
        login: userInfo.login,
        form_email: userInfo.email,
        form_phone: userInfo.phone,
        form_cellPhone: userInfo.cellPhone,
        responsibleSecretary: userInfo.secretary,
        level: userInfo.accessLevel,
      });
  }, [userInfo]);

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      email: values.form_email,
      phone: values.form_phone,
      cellPhone: values.form_cellPhone,
    };
    await api(POST_UPDATE_USER(buffer))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          updateUserInfo();
          setOpen()
          setSubmitting(false);
          enqueueSnackbar("Dados atualizados!!!", { variant: "success" });
        } else {
          setSubmitting(false);
          setOpen()
          enqueueSnackbar(`Erro ao atualizar dados!!!`, { variant: "Error" });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
      });
  };

  return (
    <DefaultModal open={open} handleClose={setOpen} title="Atualizar informações" loading={info === null}>
      <Formik
        initialValues={info}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal>
  );
};

export default EditUser;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "grid",
      // width: 400,
      [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},  
      [theme.breakpoints.up("sm")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},   
      [theme.breakpoints.up("md")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
      [theme.breakpoints.up("lg")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
      [theme.breakpoints.up("xl")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    },
  })
);

const Forms = (props) => {
  const classes = useStyles();
  const {
    values: {
      form_email,
      form_phone,
      form_cellPhone,
    },
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers >
        <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              id="form_email"
              label="Email"
              name="form_email"
              inputProps={{ autoComplete: "off" }}
              autoComplete="off"
              onChange={handleChange}
              value={form_email}
              error={touched.form_email && Boolean(errors.form_email)}
              helperText={touched.form_email && errors.form_email}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              id="form_phone"
              label="Telefone"
              name="form_phone"
              InputProps={{ inputComponent: MaskPhone }}
              inputProps={{ autoComplete: "off" }}
              onChange={handleChange}
              value={form_phone}
              error={touched.form_phone && Boolean(errors.form_phone)}
              helperText={touched.form_phone && errors.form_phone}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="none"
              size="small"
              fullWidth
              id="form_cellPhone"
              label="Celular"
              name="form_cellPhone"
              InputProps={{ inputComponent: MaskCel }}
              inputProps={{ autoComplete: "off" }}
              onChange={handleChange}
              value={form_cellPhone}
              error={touched.form_cellPhone && Boolean(errors.form_cellPhone)}
              helperText={touched.form_cellPhone && errors.form_cellPhone}
            />
      </Grid>
      </Grid>
       </DefaultModalContent>
       <DefaultModalActions style={{marginRight: "0.7rem"}}>
            <ButtonSuccess
              style={{ width: 120 }}
              variant="contained"
              color="primary"
              type="submit"
              success={false}
              loading={isSubmitting}
            >
              Salvar
            </ButtonSuccess>
      </DefaultModalActions>      
 
    </form>
  );
};
