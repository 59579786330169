import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Urgency } from "./Graphics/Urgency";
import { Gravity } from "./Graphics/Gravity";
import { Trend } from "./Graphics/Trend";
import { Status } from "./Graphics/Status";
import { Level } from "./Graphics/Level";
import { CardActions, CardActionsProblem, CardProblem } from "./Cards";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const initDate ={
  problem: 5,
  actions: 5,
  actionsProblem: 10,
  Urgency: [21, 22, 10, 28, 16],
  Gravity: [21, 22, 10, 28, 16],
  Trend:   [21, 22, 10, 28, 16],
  Status:  [5,10, 11, 4],
  Level:   [5, 2, 10, 7, 3],
}

const defaultValue ={
  problem: undefined,
  actions: undefined,
  actionsProblem: undefined,
  Urgency: [],
  Gravity: [],
  Trend:   [],
  Status:  [],
  Level:   [],
}

export const StatisticsRiskPanel = () => {
  const classes = useStyles();
  const [data, setData] = React.useState(initDate)

  const updateData=()=>{

  }

  React.useEffect(()=>{
  
  },[])

  return (
    <div className={classes.root}>
    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>

    <Grid container item xs={12} sm={12} md={12} lg={9} xl={8} justifyContent="center" spacing={3} style={{display:"flex", paddingTop: "1rem",}}>
     <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
      <CardProblem value={data?.problem}/>
     </Grid>

     <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
      <CardActions value={data?.actions}/>
     </Grid>

     <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
      <CardActionsProblem value={data?.actionsProblem}/>
     </Grid>
    </Grid>

    <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} spacing={3} style={{display:"flex", justifyContent:"flex-end"}}>
     <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
      <Status value={data?.Status}/>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
      <Level value={data?.Level}/>
     </Grid>
    </Grid>

     <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} spacing={3} style={{display:"flex", justifyContent:"flex-start"}}>
     <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
      <Gravity value={data?.Gravity}/>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
      <Urgency value={data?.Urgency}/>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
      <Trend value={data?.Trend}/>
     </Grid>
    </Grid>



  </Grid>
 </div>
  )
}

 
