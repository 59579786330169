import React from "react";

import {
  Grid,
  TextField,
  Button,
  Typography,
  Drawer,
  Box
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  GET_PRIORITY_TASK,
  GET_RESPONSIBLESECRETARY,
  GET_SECRETARY,
  GET_STATUS_TASK,
} from "../../services/options";
import { SelectApi } from "../../Components/SelectsApi";
import { Formik } from "formik";
import { SelectCustom } from "../SelectsCustom";

const initial = {
    name: "",
    responsible: null,
    responsibleSecretary: null,
    status: null,
    priority: null,
    situation: null,
  };

export default function FilterTask({
  drawerOpen,
  setDrawerOpen,
  search,
  setSearch,
}) {
  const [init, setInit] = React.useState(initial);

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
        name                : values.name,
        priority            : values.priority             === null? 0:values.priority.id,
        status              : values.status               === null? null:values.status.id,
        situation           : values.situation            === null? null:values.situation.id,
        responsible         : values.responsible          === null? 0:values.responsible.id,
        responsibleSecretary: values.responsibleSecretary === null? 0:values.responsibleSecretary.id,
    };
 
    setInit(values);
    setSearch(buffer);
    setSubmitting(false);
    setDrawerOpen(false);
  };

  return (
    <Drawer
      open={drawerOpen}
      anchor="right"
      onClose={() => setDrawerOpen(!drawerOpen)}
    >
      <Formik
        initialValues={init}
        onSubmit={submit}
      >
        {(props) => <Forms setInit={setInit} {...props} />}
      </Formik>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
     // width: 450,
      margin: "20px auto",
    //  padding: theme.spacing(2, 4, 2, 4),
      [theme.breakpoints.up("xs")]: {width: 250, padding: theme.spacing(0.5, 1, 0, 1),},  
      [theme.breakpoints.up("sm")]: {width: 300, padding: theme.spacing(0.5, 1.5, 0, 1.5),},   
      [theme.breakpoints.up("md")]: {width: 350, padding: theme.spacing(0.5, 2, 0, 2),},  
      [theme.breakpoints.up("lg")]: {width: 390, padding: theme.spacing(0.5, 2.5, 0, 2.5),},  
      [theme.breakpoints.up("xl")]: {width: 450, padding: theme.spacing(0.5, 3, 0, 3),},  
    },

    submitButton: {
      marginTop: theme.spacing(3),
      minWidth: "12rem",
    },
  })
);

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    setInit,
    resetForm,
  } = props;

  const resetValues = async () => {
    await setInit(initial);
    await resetForm({ values: initial });
    await handleSubmit()
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container className={classes.main} spacing={0}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h5" component="h1">
            Filtrar etapas por:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            id="name"
            label="Etapa"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) => {
              setFieldValue("responsibleSecretary", value);
            }}
            value={values.responsibleSecretary}
            config={GET_SECRETARY()}
            //multiple={true}
            //limitTags={1}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            onChange={(event, value) => {
              setFieldValue("responsible", value);
            }}
            value={values.responsible}
            config={GET_RESPONSIBLESECRETARY(0)}
            //multiple={true}
            //limitTags={1}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Responsável",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box  style={{ maxWidth: "45%" }}>
          <SelectApi
            fullWidth
            id="status"
            name="status"
            onChange={(e, value) => setFieldValue("status", value || null)}
            value={values.status}
            config={GET_STATUS_TASK()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Status",
            }}
          />
          </Box>
        </Grid>

        <Grid item xs={12} style={{ maxWidth: "45%" }}>
          <SelectCustom
            id="situation"
            name="situation"
            onChange={(e, value) => {
              setFieldValue("situation", value);
            }}
            value={values.situation}
            //   multiple={true}
            //  limitTags={1}
            options={[
              { id: 1, name: "No prazo" },
              { id: 2, name: "Em atraso" },
              { id: 3, name: "Concluído" },
              { id: 4, name: "Cancelado" },
              { id: 5, name: "Suspenso" },
             // { id: 4, name: "Projeto cancelado" },
            ]}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Situação",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box  style={{ maxWidth: "45%" }}>
          <SelectApi
            id="priority"
            name="priority"
            label="Prioridade"
            onChange={(e, value) => {
              setFieldValue("priority", value || null);
            }}
            value={values.priority}
            config={GET_PRIORITY_TASK()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Prioridade",
            }}
          />
         </Box>
        </Grid>
 
        <Grid item xs={12}>
        <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, marginTop: "1rem"}}>
            <Button
              style={{ marginRight: "1rem", width: 100 }}
              variant="contained"
              color="primary"
              onClick={resetValues}
              disabled={isSubmitting}
            >
              Reiniciar
            </Button>

            <Button
              style={{width: 100 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Filtrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
