import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import * as Icons from "@material-ui/icons";
import FormLoadingComponent from "../../../../Components/FormLoading";
import CardStyle from "../../../../Components/CardStyle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    padding:"0.2rem",
    [theme.breakpoints.up("xs")]: {height: "110px"},
    [theme.breakpoints.up("sm")]: {height: "110px"},
    [theme.breakpoints.up("md")]: {height: "110px"},
    [theme.breakpoints.up("lg")]: {height: "110px"},
    [theme.breakpoints.up("xl")]: {height: "110px"},  
  },
  title:{
    fontWeight: "bold",
    fontFamily: "sans-serif",
    [theme.breakpoints.up("xs")]: { fontSize: "1.3rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.4rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.2rem"},  
  },
  subtitle:{
    fontWeight: "normal",
    fontWeight: "bold",
    [theme.breakpoints.up("xs")]: { fontSize: "1.9rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.9rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "2.1rem"},  
  },
  avatar: {
    color: "black",
    [theme.breakpoints.up("xs")]: {width: theme.spacing(5), height: theme.spacing(5)},      
    [theme.breakpoints.up("sm")]: {width: theme.spacing(6), height: theme.spacing(6)},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(6), height: theme.spacing(6)},    
    [theme.breakpoints.up("lg")]: {width: theme.spacing(6), height: theme.spacing(6)},    
    [theme.breakpoints.up("xl")]: {width: theme.spacing(6), height: theme.spacing(6)},    
    
  },
  large: {
    [theme.breakpoints.up("xs")]: {width: theme.spacing(6), height: theme.spacing(6),},   
    [theme.breakpoints.up("sm")]: {width: theme.spacing(8), height: theme.spacing(8),},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(7), height: theme.spacing(7),},  
    [theme.breakpoints.up("lg")]: {width: theme.spacing(7), height: theme.spacing(7),},  
    [theme.breakpoints.up("xl")]: {width: theme.spacing(7), height: theme.spacing(7),},  
  },
}));

export const CardProjects = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Projetos
             </Typography>}
   
    value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
            {value !== undefined ? value : <Skeleton variant="text" width="2rem"/>}
           </Typography>}
           
    color="linear-gradient(60deg,rgba(3,155,229,1) 20%,#00CED1 100%)"
    customIcon={Icons.WebAsset}
   />
      // <Card elevation={1} className={classes.root}>
      //   <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
      //    avatar={ 
      //     <Avatar className={classes.large} style={{backgroundColor: "white"}} >
      //      <Icons.WebAsset className={classes.avatar} />
      //    </Avatar>}
      //    title={
      //      <Typography className={classes.title} component="h1" color="textPrimary">
      //       Projetos
      //      </Typography>}
      //     subheader={
      //       <Typography className={classes.subtitle} component="h2" color="textSecondary">
      //       {value !== undefined ? value : <FormLoadingComponent />}
      //     </Typography>}
      //   />  
      // </Card>
  );
};

export const CardSecretary = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Secretarias
             </Typography>}
   
    value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
            {value !== undefined ? value : <Skeleton variant="text" width="2rem"/>}
           </Typography>}
           
    color="linear-gradient(60deg, rgba(251,140,0,1) 0%, rgba(255,202,41,1) 100%)"
    customIcon={Icons.AccountBalance}
   />
      // <Card elevation={1} className={classes.root}>
      //   <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
      //    avatar={ 
      //     <Avatar className={classes.large} style={{backgroundColor: "white"}} >
      //      <Icons.AccountBalance variant="h4" className={classes.avatar}/>
      //    </Avatar>}
      //    title={
      //     <Typography className={classes.title} component="h1" color="textPrimary">
      //       Secretarias
      //      </Typography>}
      //     subheader={
      //       <Typography className={classes.subtitle} component="h2" color="textSecondary">
      //       {value !== undefined ? value : <FormLoadingComponent />}
      //     </Typography>}
      //   />  
      // </Card>
  );
};

 
// export const CardProjects = ({ value }) => {
//   return (
//       <Card>
//         <CardContent>
//           <Grid
//             container
//             direction="row"
//             justify="space-between"
//             alignItems="center"
//           >
//             <Grid item xl={4}>
//               <WebAsset style={{ borderRadius: 10, fontSize: 60 }} />
//             </Grid>
//             <Grid item xl={6}>
//               <Typography
//                 style={{
//                 //  fontSize: 20,
//                   fontWeight: "bold",
//                   textAlign: "center",
//                 }}
//                 variant="h5"
//               >
//                 Projetos
//               </Typography>
//               <Typography
//                 style={{
//                 //  fontSize: 30,
//                   fontWeight: "bold",
//                   color: "#CCC",
//                   textAlign: "center",
//                 }}
//                 variant="h5"
//               >
//                 {value !== undefined ? <>{value}</> : <FormLoadingComponent />}
//               </Typography>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>
//   );
// };

// export const CardSecretary = ({ value }) => {
//   return (
//       <Card>
//         <CardContent>
//           <Grid
//             container
//             direction="row"
//             justify="space-between"
//             alignItems="center"
//           >
//             <Grid item xl={4}>
//               <AccountBalance style={{ borderRadius: 10, fontSize: 60 }} />
//             </Grid>
//             <Grid item xl={6}>
//               <Typography
//                 style={{
//                 //  fontSize: 20,
//                   fontWeight: "bold",
//                   textAlign: "center",
//                 }}
//                 variant="h5"
//               >
//                 Secretarias
//               </Typography>
//               <Typography
//                 style={{
//                 //  fontSize: 30,
//                   fontWeight: "bold",
//                   color: "#CCC",
//                   textAlign: "center",
//                 }}
//                 variant="h5"
//               >
//                {value !== undefined ? <>{value}</> : <FormLoadingComponent />}
//               </Typography>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>
//   );
// };
 