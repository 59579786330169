import React from "react";
import { useParams } from "react-router-dom";

import { useSnackbar } from "notistack";
import useAxios from "../../../../../Context/Hooks/useAxios";
import { GET_TASK_LIST } from "../../../../../services/options";
import Table from "./Table";
import FormLoadingComponent from "../../../../../Components/FormLoading";
import DefaultModal, { DefaultModalContent } from "../../../../../Components/DefaultModal";


const initial = {
    name: "",
    responsible: null,
    responsibleSecretary: null,
    status: null,
    priority: null,
  };

export default function ViewTaskList({search, handleClose, title}){
    const { id } = useParams();
    const { buffer, loading, error, request } = useAxios();
    const { enqueueSnackbar } = useSnackbar();

    const updateData = async () => {
        await request(GET_TASK_LIST({ ...search, id})).then()
        .catch(e=>{
          enqueueSnackbar("Erro ao atualizar lista", { variant: "Error" });
        });
      };
    
      React.useEffect(() => {
        if(search !== null) updateData();
      }, [search]);

      return(
        <DefaultModal
         title={title}
         loading={loading}
         open={search !== null}
         handleClose={() => handleClose()}
         maxWidth="lg"
        >
         <DefaultModalContent> 
           {!loading ? <Table data={buffer} updateData={updateData} error={error} load={loading}/>:<FormLoadingComponent/>}
         </DefaultModalContent>
        </DefaultModal>
        )
    }