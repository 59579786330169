import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        '&::-webkit-scrollbar': {
          width: '12px',
          maxHeight: "20px",
          //backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.2)',
          borderRadius: '10px',
          backgroundColor: '#F5F5F5',
          
        },
        '&::-webkit-scrollbar-thumb': {
          '-webkit-border-radius': '10px',
          borderRadius: '10px',
          background: 'rgba(241, 237, 237, 0.9)', 
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)', 
        },
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
        [theme.breakpoints.up("xs")]: {fontSize: "0.75rem",}, //0
        [theme.breakpoints.up("sm")]: {fontSize: "0.80rem",}, //600
        [theme.breakpoints.up("md")]: {fontSize: "0.85rem",}, //960,
        [theme.breakpoints.up("lg")]: {fontSize: "0.90rem",}, //1366,
        [theme.breakpoints.up("xl")]: {fontSize: "1rem",}, //1920
      },
      body: {
        backgroundColor: "#F0F4F5",//'#f4f6f8',
        height: '100%',
        width: '100%'
      },
      a: {
        textDecoration: 'none',
        color: 'inherit'
      },
      typograpgy: {
        // fontSize: "1.2rem",
        // "@media (min-width:600px)": {
        //   fontSize: "1.5rem",
        // },
        // [theme.breakpoints.up("md")]: {
        //   fontSize: "2.4rem",
        // },
        color: "#AAAAAA",
        fontFamily: ["Roboto"].join(","),
        padding: theme.spacing(1),
      },
      button: {
       // height: 36
    },
      '#root': {
        height: '100%',
        width: '100%'
      }
      
    }
  },
  )
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;