import React from "react";
import { convertToHTML } from "draft-convert";
import { Formik } from "formik";
import * as Yup from "yup";
import { Typography, DialogContent, DialogActions } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { TextEditor } from "../../Editor";
 
 
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../ButtonSuccess";
import DefaultModal from "../../DefaultModal";
import { POST_UPDATE_TASK } from "../../../services/options";
import api from "../../../services/Api";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: { maxWidth: 380 },
      [theme.breakpoints.up("sm")]: { maxWidth: 450 },
      [theme.breakpoints.up("md")]: { maxWidth: 500 },
      [theme.breakpoints.up("lg")]: { maxWidth: 520 },
      [theme.breakpoints.up("xl")]: { maxWidth: 550 },
    },
  })
);

const validationSchema = Yup.object().shape({
  justification: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
});

export default function ConfirmEdit({ open, setOpen, editData, updateData }) {
  const { enqueueSnackbar } = useSnackbar();
  
  const submit = async (values, { setSubmitting }) => {
    const buffer = { ...editData, ...values };
    await api(POST_UPDATE_TASK(buffer))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          updateData();
          setOpen(false);
          enqueueSnackbar("Etapa atualizada !!!", { variant: "success" });
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Erro ao atualizar etapa`, { variant: "Error" });
        }
      })
      .catch((r) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ao atualizar etapa`, { variant: "Error" });
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DefaultModal
      open={open}
      handleClose={handleClose}
      title="Atualizar etapa"
    >
      <Formik
        initialValues={{ justification: "" }}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DialogContent className={classes.root}>
        <Typography variant="subtitle1" gutterBottom>
          Justifique a atualização da etapa!
        </Typography>
        <TextEditor
          id="justification"
          name="justification"
          label="Justificativa"
          onChange={(event) => {setFieldValue("justification", convertToHTML(event.getCurrentContent()))}}
          value={values.justification}
          error={touched.justification && Boolean(errors.justification)}
          helperText={touched.justification && errors.justification}
        />
      </DialogContent>
      <DialogActions style={{margin: 0, marginRight: "0.5rem" }}>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
        >
          Confirmar
        </ButtonSuccess>
      </DialogActions>
    </form>
  );
};
