import React from "react";
import { createStyles, makeStyles, Grid, Typography, Divider} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: 5,
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {maxWidth: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {maxWidth: "100%", height: 250},
    [theme.breakpoints.up("md")]: {maxWidth: "100%", height: 250},
    [theme.breakpoints.up("lg")]: {maxWidth: "100%", height: 250},
    [theme.breakpoints.up("xl")]: {maxWidth: "100%", height: 300},
  }
})
);

const getPercentWidth = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}

function ViewChart({series}){
 const colors = ["#00802b", "#1976D2", "#FFA500", "#FF4500", "#FF0000"];

 const options = {
    chart: {
      type: 'bar',
      // dropShadow: {
      //   enabled: true,
      //   top: 0,
      //   left: 0,
      //   blur: 1,
      //   opacity: 0.5
      // }
    },
    grid: {
      show: true,
      borderColor: "#CCC",
      strokeDashArray: "3,4",
      position: "back",
      xaxis: {
        lines: {
          show: true
        }
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: getPercentWidth(900, 5, 45),
        borderRadius: 10,
        horizontal: false,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      hideOverflowingLabels: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    legend: {
      show: false
    },
    yaxis: {
      show: false
    },
    xaxis: {
      type: 'category',
      categories: ['Pode esperar','Pouco urgente','Urgente, merece atenção no curto prazo','Muito urgente','Necessidade de ação imediata'],
      labels:{
        show: true,
        rotate: -25,
        trim: true,
        minHeight: 0,
        maxHeight: 60,
        style:{
         fontSize: "10px"
        }
      }
    }
  };
  return <ReactApexChart options={options} series={series} type="bar" height="100%" width="100%"/>
}

export const Urgency = ({value}) => {
  const classes = useStyles();
  const [data, setData] = React.useState([{
    name: "Valor",
    data: value,
  }])
  
 return (
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> Urgência </Typography>
   <Divider/>
  </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart series={data} />
  </Grid>
 </Grid>
 )
}
