import React from "react";
import { convertToHTML} from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { SelectApi } from "../../../../Components/SelectsApi";
 
import { CreateProjectContext } from "..";
import { GET_AXIS, GET_GROUP, GET_SECRETARY, GET_RESPONSIBLESECRETARY } from "../../../../services/options";
import { NumberFormatCustom } from "../../../../Components/Helper/FormatValues";
import { useNavigate } from "react-router-dom";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { Box } from "@material-ui/core";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import { TextEditor } from "../../../../Components/Editor";
import { CustomDatePicker } from "../../../../Components/CustomDatePicker";

const validationSchema = Yup.object().shape({
   name: Yup.string()
     .required("Obrigatório")
     .min(6, "O nome deve ter pelo menos 6 caracteres"),
     
  // resume: Yup.string()
  // .required("Obrigatório")
  // .min(6, "O nome deve ter pelo menos 6 caracteres"),
  // description: Yup.string().required("Obrigatório").min(8, "Obrigatório"),

   routine: Yup.number().required("Obrigatório"),
   group: Yup.object()
     .when("routine", {
       is: 0,
       then: Yup.object().required("Obrigatório").nullable(),
     }).nullable(),

   axis: Yup.object()
   .when("routine", 
   {is: 0,
   then: Yup.object().required("Obrigatório").nullable(),
   }).nullable(),

   initDate: Yup.date().required("Obrigatório").nullable("inválido").typeError("Data inválida"),
   
   deadLine: Yup.date().min(
   Yup.ref('initDate'),
     "A data final não pode ser anterior à data de inícial"
   ).required("Obrigatório").nullable("inválido").typeError("Data inválida"),

   predictedValue: Yup.string().required("Obrigatório"),
 
   responsible: Yup.object()
   .shape({
    // id: Yup.string().required("Inválido"),
   })
   .nullable()
   .required("Obrigatório"),

   responsibleSecretary: Yup.object()
   .shape({
    // id: Yup.string().required("Inválido"),
   })
   .nullable()
   .required("Obrigatório"),
});

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      [theme.breakpoints.up("xs")]: {width: 340, padding: theme.spacing(2, 1.5, 2, 1.5), }, 
      [theme.breakpoints.up("sm")]: {width: 410, padding: theme.spacing(2, 3, 2, 3), }, 
      [theme.breakpoints.up("md")]: {width: 550, padding: theme.spacing(2, 5, 2, 5), },  
      [theme.breakpoints.up("lg")]: {width: 650, padding: theme.spacing(2, 8, 2, 8), },  
      [theme.breakpoints.up("xl")]: {width: 650, padding: theme.spacing(2, 8, 2, 8), },  
      margin: "0px auto",
      border: `2px solid  ${theme.palette.divider}`,
      borderRadius: "10px",
      boxShadow: "0px 2px 3px rgba(50, 50, 93, 0.11), 0px 4px 6px rgba(0, 0, 0, 0.08)",
    },
  })
);

export const POST_VERIFY_NAME = (body) =>{
  return {
    url:  "project/checkname.php",
    method: 'post',
    data: body,
  };
}

export const TabProject = () => {
  const { projectValue, setProjectValue, handleNext } = React.useContext(CreateProjectContext);
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
  await api(POST_VERIFY_NAME({name: values.name})).then(r=>{
     setProjectValue(values);
     setSubmitting(false);
     enqueueSnackbar("Nome disponível !!!", { variant: "success" });
     handleNext();
  }).catch(e=>{
     setSubmitting(false);
     enqueueSnackbar(`Já existe um projeto com esse nome !!!`, { variant: "warning" });
    })
  };

  return (
    <Formik
      initialValues={projectValue}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} />}
    </Formik>
  );
};

const Form = (props) => {
  const navigate = useNavigate()
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    values,
    errors,
    touched,
    dirty,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = props;

  React.useEffect(() => {
    const setInitValue = (id) => {
      setFieldValue(id, null);
    };
    if (values.routine === 1) {
      setInitValue("group");
      setInitValue("axis");
    }
  }, [values?.routine]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            margin="normal"
            size="small"
            id="name"
            label="Projeto"
            name="name"
            autoFocus
            inputProps={{ autoComplete: 'off' }} 
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            margin="normal"
            size="small"
            id="resume"
            label="Resumo"
            name="resume"
            inputProps={{ autoComplete: 'off' }} 
            onChange={handleChange}
            value={values.resume}
            error={touched.resume && Boolean(errors.resume)}
            helperText={touched.resume && errors.resume}
          />
        </Grid> */}

        <Grid item xs={4} style={{marginTop: '20px'}}>
        <FormControl component="fieldset">
            <FormLabel component="legend">Projeto de rotina?</FormLabel>
            <RadioGroup
              aria-label="routine"
              name="routine"
              id="routine"
              value={values.routine}
              onChange={(e, value) => {
                setFieldValue("routine", Number(value))}
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Sim"
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Não"
              />
            </RadioGroup>
          </FormControl>
          </Grid>

          <Grid item xs={8} style={{ display: "grid" }}>
            <Grid item xs={12}>
              <SelectApi
                fullWidth
                disabled={Boolean(values.routine === 1)}
                id="group"
                name="group"
                onChange={(e, value) => {
                  setFieldValue("group", value || null) 
                  setFieldValue("axis", null)}
                }
                value={values.group}
                config={GET_GROUP}
                textFieldProps={{
                  fullWidth: true,
                  size: "small",
                  margin: "normal",
                  variant: "outlined",
                  label: "Portifólio",
                }}
                error={touched.group && Boolean(errors.group)}
                helperText={touched.group && errors.group}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectApi
                fullWidth
                disabled={Boolean(values.group === null | values.routine === 1)}
                id="axis"
                name="axis"
                onChange={(e, value) => setFieldValue("axis", value || null)}
                value={values.axis}
                config={GET_AXIS(values.group?.id)}
                textFieldProps={{
                  label: "Programa",
                  fullWidth: true,
                  size: "small",
                  margin: "normal",
                  variant: "outlined",
                }}
                error={touched.axis && Boolean(errors.axis)}
                helperText={touched.axis && errors.axis}
              />
            </Grid>
          </Grid>
 

        <Grid item xs={6} style={{ display: "grid" }}>
          <Grid item xs={12} style={{ maxWidth: "90%" }}>
          <CustomDatePicker
           fullWidth
           id="initDate"
           name="initDate"
           label="Data inicial"
           margin="normal"
           maxDate={values.deadLine || undefined}
           onChange={(value) =>{
           setFieldValue("initDate",  value || null )}}
           value={values.initDate}
           error={touched.initDate && Boolean(errors.initDate)}
           helperText={touched.initDate && errors.initDate}
          />
          </Grid>

          <Grid item xs={12} style={{ maxWidth: "90%" }}>
          <CustomDatePicker
            fullWidth
            id="deadLine"
            name="deadLine"
            label="Data limite"
            margin="normal"
            minDate={values.initDate || undefined}
            onChange={(value) =>{
            setFieldValue("deadLine",  value || null)}}
            value={values.deadLine}
            error={touched.deadLine && Boolean(errors.deadLine)}
            helperText={touched.deadLine && errors.deadLine}
           />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            id="predictedValue"
            label="Valor previsto"
            name="predictedValue"
            inputProps={{ autoComplete: 'off' }} 
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange}
            value={values.predictedValue}
            error={touched.predictedValue && Boolean(errors.predictedValue)}
            helperText={touched.predictedValue && errors.predictedValue}
          />
        </Grid>

        <Grid item xs={12} style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
          <TextEditor
            id="description"
            name="description"
            label="Objetivo"
            onChange={(event) => {
              setFieldValue("description", convertToHTML(event.getCurrentContent()));
            }}
            value={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) =>
            {  setFieldValue("responsibleSecretary", value)
               setFieldValue("responsible", null)}
            }
            value={values.responsibleSecretary}
            config={GET_SECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
            error={
              touched.responsibleSecretary &&
              Boolean(errors.responsibleSecretary)
            }
            helperText={
              touched.responsibleSecretary && errors.responsibleSecretary
            }
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            disabled={values.responsibleSecretary === null}
            onChange={(event, value) =>{
              (value?.id !== null || value === null ? setFieldValue("responsible", value || null):
              setFieldValue("responsible", null))
            }}
            value={values.responsible}
            config={GET_RESPONSIBLESECRETARY(values.responsibleSecretary?.id)}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Responsável",
            }}
            error={touched.responsible && Boolean(errors.responsible)}
            helperText={touched.responsible && errors.responsible}
          />
        </Grid>

        <Grid item xs={12}>
         <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, marginTop: "1rem"}}>
          <Button
            style={{marginRight: 10}}
            variant="contained" 
            color="primary" 
            onClick={()=>{
              navigate('/projects');
              enqueueSnackbar(`O projeto não foi criado!`, { variant: "warning" });
            }}
          > Cancelar
          </Button>
          <ButtonSuccess
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             loading={isSubmitting}
            > Próximo
            </ButtonSuccess>
          </Box>
         </Grid>
      </Grid>
    </form>
  );
};


// projectInitDate: Yup.date().required("Obrigatório").nullable(),
// projectDeadLine: Yup.date().required("Obrigatório").nullable(),
// projectPredictedValue: Yup.number().required("Obrigatório").nullable(),

// // description: Yup.string()
// //   .required("Obrigatório")
// //   .min(8, "Obrigatório")
// //   .max(2000, "Limite maximo de 2000 caracteres"),

// initDate: Yup.date().min(
// Yup.ref('projectInitDate'),
// "A data inícial da etapa não pode ser anterior à data inícial do projeto"
// ).max(Yup.ref('projectDeadLine'),
// "A data inícial não pode ser posterior à data de limite do projeto").required("Obrigatório").nullable(),

// deadLine: Yup.date().min(
// Yup.ref('initDate'),
// "A data final da etapa não pode ser anterior à data inícial da etapa"
// ).max(
// Yup.ref('projectDeadLine'),
// "A data final da etapa não pode ser posterior à data de final prevista do projeto"
// ).required("Obrigatório").nullable(),
