import React from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

import { convertToHTML } from "draft-convert";
import { SelectApi } from "../../../Components/SelectsApi";
import {
  GET_RESPONSIBLESECRETARY,
  POST_INSERT_SECRETARY,
} from "../../../services/options";
import { MaskPhone } from "../../../Components/Helper/FormatValues";
import useAxios from "../../../Context/Hooks/useAxios";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import { TextEditor } from "../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 520, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const initialValues = {
  name: "",
  address: "",
  phone: "",
  email: "",
  responsible: null,
  description: "<p></p>",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
  address: Yup.string().required("Obrigatório"),
  phone: Yup.string().required("Obrigatório"),
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  responsible: Yup.object().shape().required("Obrigatório").nullable(),
  // description: Yup.string().required("Obrigatório"),
});

export default function NewSecretary({ open, setOpen, updateData }) {
  const { request } = useAxios();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
    await request(POST_INSERT_SECRETARY(values))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          updateData();
          enqueueSnackbar("Secretaria criada!!!", { variant: "success" });
          setOpen(false);
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Erro`, { variant: "Error" });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
      });
  };
  return (
    <DefaultModal open={open} handleClose={()=>setOpen()} title="Nova secretaria">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers >
        <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="email"
            label="email"
            name="email"
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.email}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            name="address"
            label="Endereço"
            id="address"
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.address}
            error={touched.address && Boolean(errors.address)}
            helperText={touched.address && errors.address}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="phone"
            label="Telefone"
            name="phone"
            InputProps={{ inputComponent: MaskPhone }}
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.phone}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            label="Responsável"
            onChange={(e, value) => {
              setFieldValue("responsible", value || null);
            }}
            value={values.responsible}
            config={GET_RESPONSIBLESECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Responsável",
            }}
            error={touched.responsible && Boolean(errors.responsible)}
            helperText={touched.responsible && errors.responsible}
          />
        </Grid>
        <Grid item xs={12}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {
              setFieldValue(
                "description",
                convertToHTML(event.getCurrentContent())
              );
            }}
            data={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>
        </Grid>
        </DefaultModalContent>

         <DefaultModalActions style={{marginRight: "0.5rem"}}>
            <ButtonSuccess
              style={{ width: 120 }}
              variant="contained"
              color="primary"
              type="submit"
              success={false}
              loading={isSubmitting}
            >
              Criar
            </ButtonSuccess>
          </DefaultModalActions>
    </form>
  );
};

/*Login:
  Nome:
  Secretaria:
  Email: por padrão ser o mesmo da secretaria responsável
  Status: Ativo / Inativo
  Nível: 
  Senha: 
  */
