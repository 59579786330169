import React from "react";
import {
  createStyles,
  makeStyles,
  Grid,
  Typography,
  Divider,
  Paper
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";
import ViewList from "../ViewList";

// Gráfico em barra Por eixo do Plano de governo
export const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: 5,
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {maxWidth: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {maxWidth: "100%", height: 300},
    [theme.breakpoints.up("md")]: {maxWidth: "100%", height: 300},
    [theme.breakpoints.up("lg")]: {maxWidth: "100%", height: 300},
    [theme.breakpoints.up("xl")]: {maxWidth: "100%", height: 350},
    paddingTop:"1rem",
  }
})
);

const GET_INFO = (id) => {
  return {
    method: "get",
    url: `dashboard/axisProgress.php?id=${id}`,
  };
};

function ViewChart({data, handleClick}){
  const {bufferValue, buffer, sizeBar} = data
  const series = [
    {
      name: "Projetos",
      data: bufferValue,
    },
  ]

 const options = {
  chart: {
    // dropShadow: {
    //   enabled: true,
    //   top: 0,
    //   left: 0,
    //   blur: 1,
    //   opacity: 0.5
    // },
    events: {
       dataPointSelection: (event, chartContext, config) => {
       handleClick(config.dataPointIndex)
      }
    },
  },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        startingShape: "rounded",
        endingShape: "rounded",
        columnWidth: sizeBar,
        rangeBarOverlap: false,
        rangeBarGroupRows: false,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels:{
        show: false
      }
    },
    xaxis: {
      type: 'category',
      categories: buffer,
      labels:{
        show: true,
        rotate: -25,
        trim: true,
        minHeight: 0,
        maxHeight: 60,
        style:{
         fontSize: "10px"
        }
      }
    },
  }
  
return <ReactApexChart options={options} series={series} type="bar" width="100%" height="100%"/>
}

const init = {
  bufferValue: [],
  buffer: [],
  ids   : [],
  sizeBar: "16%"
}

export default function AmountAxis({group}) {
  const classes = useStyles();
  const [data, setData] = React.useState(init)
  const [view, setView] = React.useState(null)
 
 
  const handleClose =()=> setView(null)
  const handleClick =(type)=>{
   setView({
    name: "",
    group: group.id,
    axis: data.ids[type],
    responsible: null,
    responsibleSecretary: null,
    status: null,
    type: {
      routine: false,
      governmentPlan: true,
    },
  })
  }
  
  const getPercentWidth = (w, n, x) => {
    const percentWidth = Math.round((x / (w / n)) * 100)
    return `${percentWidth}%`
  }

  const updateData = async () => {
   await api(GET_INFO(group.id)).then(({data}) => {
      if (data === null) return

      const bufferValue = data.map((ret) => Number(ret.axisQtd));
      const buffer      = data.map((ret) => ret.axis);
      const ids         = data.map((ret) => ret.axisId);
      const sizeBar     = getPercentWidth(1140, buffer.length, 40) //buffer.length >= 4?"25%": "10%"

      setData({bufferValue, buffer, sizeBar, ids})
    }).catch(e=>{
      setData(init)
    });
  };

  React.useEffect(() => {
    updateData();
  }, [group]);

 return (
 <>
 <ViewList search={view} handleClose={handleClose} title={group.name}/>
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> {group.name} </Typography>
   <Divider />
  </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart data={data} handleClick={handleClick} />
  </Grid>
 </Grid>     
 </>
 );
}
