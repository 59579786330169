import React from "react";
import { Grid, createStyles, makeStyles, Button, Box, Fab, Tooltip, Chip } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import Table from "./Table";
import DialogTask from "./DialogTask";
import Confirm from "./Confirm"
import { CreateProjectContext } from "..";
import { FormatRealNumber } from "../../../../Components/Helper/FormatValues";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "block",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 400,
    },
    paper: {
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1),
      borderRadius: 5,
    },
    btn: {
      alignitens: "right",
     // maxWidth: "500px",
    },
    table: {
      padding: theme.spacing(1, 0, 1, 0),
      minHeight: 610,
    },
  })
);

export default function TabTask() {
  const classes = useStyles();
  const {
    taskValue,
    setTaskValue,
    projectValue,
    handleBack,
    handleNext,
  } = React.useContext(CreateProjectContext);
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
 
  const submit = (values, { setSubmitting }) => {
     const buffer ={  
     name: values.name,
     initDate: values.initDate,
     deadLine: values.deadLine,
     predictedValue: !values.predictedValue ? 0 :values.predictedValue,
     description: values.description,
     priority: {id: "2", name: "Média"},
     responsible: values.responsible,
     responsibleSecretary: values.responsibleSecretary}
    setSubmitting(false);
    setOpen(false);
    setTaskValue([...taskValue, buffer]);
  };

  const onCheckValue =()=>{
    calcValue() > projectValue.predictedValue ? setOpenConfirm(true):handleNext()
  }

  const calcValue=()=>{
    if(taskValue.length > 0) 
    return taskValue.reduce((curr, index) => curr + parseFloat(index.predictedValue,2), 0)
    return 0
  }

  return (
    <Grid container className={classes.root}>
      <Confirm handleNext={handleNext} open={openConfirm} setOpen={setOpenConfirm}/> 
      
      <Grid item xs={12} className={classes.btn}>
      <Tooltip placement="top" title="Nova etapa" aria-label="Nova etapa">
         <Fab size="small" color="primary" aria-label="Nova etapa"   
         onClick={() => {setOpen(true)}}>
          <Icons.Add/>
         </Fab>
        </Tooltip>
       {open && <DialogTask submit={submit} open={open} setOpen={setOpen} />}

       <Chip
        style={{marginLeft: 25, fontWeight: 550}}
        label={`Valor previsto do projeto: ${FormatRealNumber(projectValue.predictedValue)}`}
        clickable
        color="primary"
      />

      <Chip
        style={{marginLeft: 25, fontWeight: 550, backgroundColor: calcValue() > projectValue.predictedValue?"#FE6941":"#9CF82F"}}
        label={`Valor previsto total das etapas: ${FormatRealNumber(calcValue())}`}
        clickable
      />
      </Grid>

      <Grid item xs={12} className={classes.table} >
        <Table data={taskValue} setData={setTaskValue}/>
      </Grid>

      <Grid item xs={12}>
      <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, marginTop: 0}}>
      <Button 
      style={{marginRight: 10, width: 120}}
      variant="contained" 
      color="primary" 
      type="submit" 
      onClick={()=>handleBack()}>Voltar
      </Button>
      <Button 
       style={{width: 120}}
       variant="contained" 
       color="primary" 
       type="submit" 
       disabled={!taskValue.length} onClick={()=>onCheckValue()}> Próximo </Button>
       </Box>
      </Grid>

    </Grid>
  );
}
