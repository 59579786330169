import React from "react";
import { Link } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Divider,
  Box,
  LinearProgress,
} from "@material-ui/core";

import { ArrowBack, Mail } from "@material-ui/icons";
import DialogSendEmail from "./DialogSendEmail";
import api from "../../services/Api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      minHeight: '100vh',
      gap: theme.spacing(5),
      padding: theme.spacing(2),
      backgroundColor: '#FFFFFF',
    },
    sloganTitle: {
      marginBottom: theme.spacing(2),
    },
    form: {
      padding: theme.spacing(2),
      width: "400px",
    },
    submit: {
      marginTop: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },

  })
);

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
});

export const RQST = (body) => {
  return {
    url: "auth/recovery.php",
    method: "post",
    headers: {
      'Content-Type': 'application/json',
     },
    data: body,
  };
};


//https://back.draftmanagementproject.com/api/auth/recovery.php?email=macrab@msn.com
export default function ForgotPassword() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
     await api(RQST(values)).then(r=>{
      const {data} = r;
      if(data.status === "success"){
        setOpen(true)
        enqueueSnackbar("Verifique seu email !!!", { variant: "success" }) 
        setSubmitting(false);
      }else{
        setSubmitting(false);
        enqueueSnackbar(`Email inválido !!!`, { variant: "error" });
      } 
     }).catch(e=>{
      setSubmitting(false);
      enqueueSnackbar(`Erro !!!`, { variant: "error" });
     })

    },
  });
  return (
    <div className={classes.root}>
      <DialogSendEmail open={open} setOpen={setOpen}/>
      <Box style={{ marginRight: "2rem" }}>
        <img
          src="/logo.png"
          alt="SISTEMA DE GERENCIAMENTO DE PROJETOS MUNICIPAIS"
        />
      </Box>
      <Paper className={classes.form} elevation={3}>
        <Box textAlign="center">
          <Typography component="h3" variant="h5">
            Esqueceu sua senha?
          </Typography>
        </Box>

        <form noValidate onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            placeholder="Seu e-mail"
            name="email"
            autoComplete="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
            }}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <Button
            className={classes.submit}
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Enviar
          </Button>
          {formik.isSubmitting && <LinearProgress/>}
        </form>

        <Divider className={classes.divider} variant="fullWidth" />

        <Box mt={1} textAlign="center">
          <Link to="/auth" passhref="true">
            <Button color="primary" startIcon={<ArrowBack />} variant="text" fullWidth>
              Voltar
            </Button>
          </Link>
        </Box>
      </Paper>
    </div>
  );
}
