import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";

const initialValues = {
  form_password: "",
  form_confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  form_password: Yup.string()
    .required("Obrigatório")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial"
    ),
  form_confirmPassword: Yup.string()
    .required("Obrigatório")
    .oneOf([Yup.ref("form_password"), null], "A senhas não correspondem!"),
});

export const POST_ADM_REFRESH_USER_PASSWORD = (body) =>{
  return {
    url: "administrator/alterpassword.php",
    method: 'post',
    data: body,
  };
}

const NewPassword = ({userId, open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      id: userId,
      password: values.form_password};
    await api(POST_ADM_REFRESH_USER_PASSWORD(buffer)).then(r=>{
      const {data} = r;
      if(data.status === "success"){
        enqueueSnackbar("Senha alterada !!!", { variant: "success" }) 
        setSubmitting(false);
        setOpen(false);
      }else{
        setSubmitting(false);
        enqueueSnackbar(`Erro ao alterar senha!!!`, { variant: "error" });
      } 

    }).catch(e=>{
      setSubmitting(false);
      enqueueSnackbar(`Erro ao alterar senha ${e.message} !!!`, { variant: "error" });
    })
  };

  return (
    <DefaultModal open={open} handleClose={()=>setOpen(false)} title="Alterar senha">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms setOpen={setOpen} {...props} />}
      </Formik>
    </DefaultModal>
  );
};

export default NewPassword;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      // width: 400,
      [theme.breakpoints.up("xs")]: {
        width: 250,
        padding: theme.spacing(0.5, 1, 0, 1),
      },
      [theme.breakpoints.up("sm")]: {
        width: 300,
        padding: theme.spacing(0.5, 1, 0, 1),
      },
      [theme.breakpoints.up("md")]: {
        width: 300,
        padding: theme.spacing(0.5, 1, 0, 1),
      },
      [theme.breakpoints.up("lg")]: {
        width: 350,
        padding: theme.spacing(0.5, 1, 0, 1),
      },
      [theme.breakpoints.up("xl")]: {
        minWidth: 400,
        padding: theme.spacing(0.5, 1, 0, 1),
      },
    },
  })
);
const Forms = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const classes = useStyles();
  const {
    values: { form_password, form_confirmPassword },
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
    setOpen,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="form_password"
            name="form_password"
            type={showPassword ? "text" : "password"}
            label="Nova senha"
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={form_password}
            error={touched.form_password && Boolean(errors.form_password)}
            helperText={touched.form_password && errors.form_password}
          />
          </Grid>
      
          <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            name="form_confirmPassword"
            id="form_confirmPassword"
            label="Confirme a nova senha"
            type={showConfirmPassword ? "text" : "password"}
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirmPassword visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={form_confirmPassword}
            error={
              touched.form_confirmPassword &&
              Boolean(errors.form_confirmPassword)
            }
            helperText={
              touched.form_confirmPassword && errors.form_confirmPassword
            }
          />
          </Grid>
        </Grid>
        </DefaultModalContent>
        
        <DefaultModalActions style={{marginRight: "0.5rem"}}>
          <ButtonSuccess
             style={{width: 100}}
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             disabled={!dirty}
             loading={isSubmitting}
            > Alterar
            </ButtonSuccess>
       </DefaultModalActions>
    </form>
  );
};
