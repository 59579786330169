import React from "react";

import {
  Grid,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Checkbox,
  FormGroup,
  Box,
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  GET_AXIS,
  GET_GROUP,
  GET_RESPONSIBLESECRETARY,
  GET_SECRETARY,
} from "../../../../services/options";
import { SelectApi } from "../../../../Components/SelectsApi";
import { SelectCustom } from "../../../../Components/SelectsCustom";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess"; 
import { CustomDatePicker } from "../../../../Components/CustomDatePicker";

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      [theme.breakpoints.up("xs")]: {width: 320, padding: theme.spacing(1, 2), },
      [theme.breakpoints.up("sm")]: {width: 410, padding: theme.spacing(1, 2), },
      [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(2, 4), },
      [theme.breakpoints.up("lg")]: {width: 520, padding: theme.spacing(2, 4), },
      [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(2, 4), },
      margin: "1rem auto",
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "10px",
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
      display: "flex",
      alignItems: "center",
      alignContent: "center",
    },
  })
);

const Form = (props) => {
  const classes = useStyles();
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    handleReset,
    dirty,
  } = props;

  React.useEffect(() => {
    if (!values.isGovernmentPlan) {
      setFieldValue("group", null);
      setFieldValue("axis", null);
    }
  }, [values.isGovernmentPlan]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container className={classes.main}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h5">Filtrar projetos por: </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
          />
        </Grid>

        <Grid item xs={6} style={{ marginTop: "20px" }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo de projeto</FormLabel>
            <FormGroup>
              <FormControlLabel
                label="Rotina"
                control={
                  <Checkbox
                    id="isRoutine"
                    name="isRoutine"
                    checked={values.isRoutine}
                    onChange={handleChange}
                  />
                }
              />
              <FormControlLabel
                label="Plano de governo"
                control={
                  <Checkbox
                    id="isGovernmentPlan"
                    name="isGovernmentPlan"
                    checked={values.isGovernmentPlan}
                    onChange={handleChange}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6} style={{ display: "grid" }}>
          <Grid item xs={12} zeroMinWidth>
            <SelectApi
              fullWidth
              disabled={Boolean(!values.isGovernmentPlan)}
              id="group"
              name="group"
              onChange={(e, value) => {
                if (values.group?.id !== value?.id) setFieldValue("axis", null);
                setFieldValue("group", value);
              }}
              value={values.group}
              config={GET_GROUP}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                margin: "normal",
                variant: "outlined",
                label: "Portifólio",
              }}
            />
          </Grid>

          <Grid item xs={12} zeroMinWidth>
            <SelectApi
              fullWidth
              disabled={Boolean(
                values.group === null || !values.isGovernmentPlan
              )}
              //       multiple={true}
              //      limitTags={1}
              id="axis"
              name="axis"
              onChange={(e, value) => setFieldValue("axis", value)}
              value={values.axis}
              config={GET_AXIS(values.group?.id)}
              textFieldProps={{
                label: "Programa",
                fullWidth: true,
                size: "small",
                margin: "normal",
                variant: "outlined",
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) => {
              setFieldValue("responsibleSecretary", value);
            }}
            value={values.responsibleSecretary}
            config={GET_SECRETARY()}
            //  multiple={true}
            //  limitTags={1}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            onChange={(event, value) => {
              setFieldValue("responsible", value);
            }}
            value={values.responsible}
            config={GET_RESPONSIBLESECRETARY(0)}
            //  multiple={true}
            // limitTags={3}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Responsável",
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ display: "inline-flex", alignItems: "end" }}>
          <Grid item xs={5}>
           <CustomDatePicker
            fullWidth
            id="initDate"
            name="initDate"
            label="Início"
            margin="normal"
            maxDate={values.deadLine || undefined}
            onChange={(value) =>{
            setFieldValue("initDate",  value || null )}}
            value={values.initDate}
           />
          </Grid>

          <Grid item xs={2} style={{textAlign: "center", }}>
          <Typography variant="h6" gutterBottom>
            Até
          </Typography>
          </Grid>

          <Grid item xs={5}>
           <CustomDatePicker
            fullWidth
            id="deadLine"
            name="deadLine"
            label="Fim"
            margin="normal"
            minDate={values.initDate|| undefined}
            onChange={(value) =>{
            setFieldValue("deadLine",  value || null )}}
            value={values.deadLine}
           />
          </Grid>
        </Grid>
 
    

        <Grid item xs={12} style={{ maxWidth: "42%" }}>
          <SelectCustom
            id="status"
            name="status"
            onChange={(e, value) => {
              setFieldValue("status", value);
            }}
            value={values.status}
            //   multiple={true}
            //  limitTags={1}
            options={[
              { id: 0, name: "Não iniciado" },
              { id: 1, name: "Em andamento" },
              { id: 2, name: "Em atraso" },
              { id: 3, name: "Cancelado" },
              { id: 4, name: "Concluído" },
              { id: 5, name: "Em andamento com atraso" },
              { id: 6, name: "Concluído com atraso" },
              { id: 7, name: "Suspenso" },
            ]}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Situação",
            }}
          />
        </Grid>

        {/* <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Detalhar</FormLabel>
            <FormGroup>
              <FormControlLabel
               label="Status"
                control={<Checkbox
                         checked={values.detail.status}
                         onChange={handleChange}
                         id="detail.status"
                         name="detail.status"/>}
              />
              <FormControlLabel
                label="Finanças"
                control={<Checkbox
                          id="detail.finances"
                          name="detail.finances"
                          checked={values.detail.finances}
                          onChange={handleChange}/>}       
              />

              <FormControlLabel
                label="Avanço físico"
                control={<Checkbox
                          id="detail.physicalProgress"
                          name="detail.physicalProgress"
                          checked={values.detail.physicalProgress}
                          onChange={handleChange}/>} 
              />

              <FormControlLabel
                label="Progresso"
                control={<Checkbox
                          id="detail.progress"
                          name="detail.progress"
                          checked={values.detail.progress}
                          onChange={handleChange}/>} 
              />
        
            </FormGroup>
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, margin: 0}}>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              Reiniciar
            </Button> */}

            <ButtonSuccess
              variant="contained"
              color="primary"
              type="submit"
              success={false}
              loading={isSubmitting}
            >
              Visualizar
            </ButtonSuccess>
          </Box> 
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
