import React from "react";
import { Grid, createStyles, makeStyles, } from "@material-ui/core";
import Table from "./Table";
import { CardCurrentGUT, CardFeedBack, CardPercent } from "./Cards";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      paddingTop: "0.5rem",
      [theme.breakpoints.up("xs")]: { minHeight: 440},     
      [theme.breakpoints.up("sm")]: { minHeight: 440},   
      [theme.breakpoints.up("md")]: { minHeight: 440},  
      [theme.breakpoints.up("lg")]: { minHeight: 540},  
      [theme.breakpoints.up("xl")]: { minHeight: 700},   
    },
    table:{
      marginTop: "0.5rem",
      boxShadow: "1px 3px 4px rgba(50, 50, 93, 0.11), 2px 4px 6px rgba(0, 0, 0, 0.08)",
      border: `2px solid  ${theme.palette.divider}`,
      borderRadius: "0.6rem",
    }
  })
);

const init ={
    average:"Nível 3",
    percent: 48,
    feedback: "Dano regular, mais cedo possível, mas irá piorar em médio prazo",
    list : [
    {average:"Nível 1", qtd: 1, percent: "12%", feedback:"Dano mínimo, não há pressa, não vai piorar e pode até melhorar"},
    {average:"Nível 2", qtd: 7, percent: "13%", feedback:"Dano leve, pode aguardar, mas irá piorar em longo prazo"},
    {average:"Nível 3", qtd: 3, percent: "14%", feedback:"Dano regular, mais cedo possível, mas irá piorar em médio prazo"},
    {average:"Nível 4", qtd: 5, percent: "15%", feedback:"Grande dano, com alguma urgência, mas irá piorar em pouco tempo"},
    {average:"Nível 5", qtd: 9, percent: "16%", feedback:"Dano gravíssimo, ação imediata e vai piorar rapidamente"},
  ]
}

export const ReviewPanel = () => {
  const style = useStyles();
  const [data, setData] = React.useState(init)

  const updateData= async()=>{
    setData(init)
  }

  React.useEffect(()=>{
    updateData()
  },[])

  return (
    <Grid 
    container
    direction="row"
    justifyContent="center"
    alignItems="flex-start"
    alignContent="flex-start"

    spacing={3} className={style.root}>
      <Grid item xs={12} sm={4} md={4} lg={3}  xl={3}>
        <CardCurrentGUT value={data.average}/>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={3}  xl={3}>
        <CardPercent value={data.percent}/>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={3}  xl={3}>
        <CardFeedBack value={data.feedback}/>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={9} xl={9} className={style.table}>
       <Table data={data?.list} error={null} load={false}/>
      </Grid>
     
    </Grid>
  )
}

 
